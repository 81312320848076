<template>
  <!-- give the sidebar z-50 class so its higher than the navbar if you want to see the logo -->
  <!-- you will need to add a little "X" button next to the logo in order to close it though -->
  <div class="w-1/2 md:w-1/3 lg:w-64 fixed md:top-0 md:left-0 h-screen lg:block bg-gray-100 border-r z-30"
       :class="sideBarOpen ? '' : 'hidden'" id="main-nav">

    <div class="w-full h-20 border-b flex px-4 items-center mb-8 mt-4">
      <router-link to="/" class="font-semibold text-3xl text-red-400 pl-4 text-center my-4">
        <img src="@/assets/images/logo.png">
      </router-link>
    </div>

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/user/list" label="Utilisateurs">
        <WorkIcon slot="icon"/>
      </SidebarButton>
    </div>

    <!--    <div class="mb-4 px-4">-->
    <!--      <SidebarButton to="/dashboard/customer/list" label="Clientèle">-->
    <!--        <WorkIcon slot="icon" />-->
    <!--      </SidebarButton>-->
    <!--    </div>-->

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/product/list" label="Produits">
        <WorkIcon slot="icon"/>
      </SidebarButton>
    </div>

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/order/list" label="Commandes">
        <AnalyticIcon slot="icon"/>
      </SidebarButton>
    </div>

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/transaction/list" label="Transactions">
        <WorkIcon slot="icon"/>
      </SidebarButton>
    </div>

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/menu/list" label="Menus">
        <AnalyticIcon slot="icon"/>
      </SidebarButton>
    </div>

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/notification" label="Notifications">
        <AnalyticIcon slot="icon"/>
      </SidebarButton>
    </div>

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/article/list" label="Articles">
        <AnalyticIcon slot="icon"/>
      </SidebarButton>
    </div>

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/banner/list" label="Bannières">
        <AnalyticIcon slot="icon"/>
      </SidebarButton>
    </div>

    <div class="mb-4 px-4">
      <SidebarButton to="/dashboard/setting" label="Réglages">
        <AnalyticIcon slot="icon"/>
      </SidebarButton>
    </div>

    <div class="mt-20">
      <SidebarButton to="/logout" label="Déconnexion">
        <AnalyticIcon slot="icon"/>
      </SidebarButton>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex';
import SidebarButton from '@/components/SidebarButton';
import WorkIcon from '@/components/icons/WorkIcon';
import AnalyticIcon from '@/components/icons/AnalyticIcon';

export default {
  name: 'Sidebar',
  components: {AnalyticIcon, WorkIcon, SidebarButton},
  computed: {
    ...mapState(['sideBarOpen'])
  }
};
</script>
