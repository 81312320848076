<template>
  <ListView title="">
    <table>
      <thead>
      <tr>
        <th>Nom</th>
        <th>Action</th>
      </tr>
      </thead>
      <draggable v-model="categories" tag="tbody">
        <!--        <transition-group tag="tbody">-->
        <tr v-for="(category, index) of categories" :key="category._id">
          <td>{{ category.name }}</td>
          <td>
            <div class="grid-cols-2 grid gap-2">
              <StyledButton name="Éditer" @click="$emit('edit', index)" />
              <StyledButton name="Supprimer" @click="$emit('remove', category._id, index)" />
            </div>
          </td>
        </tr>
        <!--        </transition-group>-->
      </draggable>
    </table>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";
import Draggable from "vuedraggable";
import StyledButton from "@/components/StyledButton";

export default {
  name: "CategoryList",
  components: { StyledButton, ListView, Draggable },
  props: {
    categories: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>

<style scoped>

</style>
