<template>
  <div class="container mx-auto ">
    <header class="col-span-3 px-6 my-6">
      <h2 class="text-2xl font-bold text-gray-500 uppercase">Catégories de produits</h2>
    </header>
    <div class="grid grid-cols-3 bg-white overflow-hidden rounded-lg shadow-md bg-white p-6">
      <div class="col-span-3">
        <b-button :outlined="false" size="is-small" icon-left="arrow-left-bold" @click="previousCategory"
                  v-show="currentState.level !== 1"></b-button>
      </div>
      <div class="col-span-3 xl:col-span-1">
        <CategoryEdit @created="addCategory" :categories="categories"
                      ref="categoryEdit" :current-state="currentState"/>
      </div>
      <div class="col-span-3 xl:col-span-2">
        <span v-if="currentState.level !==1" class="text-xl text-gray-600">Parent: {{ currentParent }}</span>
        <CategoryList :categories="categories" @remove="removeCategory($event)" @edit="updateCategory"
                      @view="showCategory"/>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryList from "@/pages/Product/Category/List";
import CategoryEdit from "@/pages/Product/Category/Edit";

export default {
  name: "CategoryCompose",
  components: {CategoryEdit, CategoryList},
  created() {
    this.getCategories();
  },
  data() {
    return {
      currentParent: '',
      categories: [],
      errors: null,
      categoryUpdate: {},
      currentState: {
        level: 1,
        parent: "",
        name: ""
      },
      previousState: [],
    };
  },
  methods: {
    showCategory(category) {
      this.getCategories(category.level + 1, category._id);
      this.previousState.push(this.currentState);
      this.currentState = {
        level: category.level + 1,
        parent: category._id,
        name: category.name,
      }
      if (category.level !== 1) {
        this.currentParent = category.name;
        // this.category.parent = category.parent;
        // this.category.level = category.level;
      } else {
        // this.category.parent = null;
        this.level = 1;
      }
    },
    previousCategory() {
      const previousState = this.previousState.pop();
      this.getCategories(previousState.level, previousState.parent, previousState.name)

      this.currentState = previousState;
    },
    addCategory(category) {
      this.categories.push(category);
    },
    async getCategories(level = 1, parent = "") {
      let query = {
        level: level
      };

      if (this.$route.query.parent || parent !== "") {
        query = {parent: this.$route.query.parent || parent, level: level}
      }

      const {data} = await this.$http.get("product_category", {
        params: query
      });

      if (data) {
        this.categories = data;
      }
    },
    updateCategory(category) {
      this.$refs.categoryEdit.editForm(category);
    },
    async removeCategory(categoryId) {
      try {
        const {status} = await this.$http.delete("product_category/" + categoryId);

        if (status === 200) {
          const index = this.categories.findIndex(category => category._id === categoryId);
          this.categories.splice(index, 1);
        }
      } catch (e) {
        this.error = e.response.data.message;
      }
    }
  }
};
</script>

<style scoped>

</style>
