<template>
  <Box>
    <template>
      <header class="relative mb-20 w-full">
        <div class="absolute sm:top-1 sm:left-1">
          <h2>Commande: <span class="text-gray-500 font-thin uppercase">#{{ order.reference }}</span></h2>
        </div>
        <!--      <div class="absolute right-0">-->
        <!--        <SVGLink :href="`/invoice/${order._id}`">-->
        <!--          <PrinterIcon/>-->
        <!--        </SVGLink>-->
        <!--      </div>-->
      </header>
      <div class="grid lg:grid-cols-2 sm:gap-2">
        <div class="grid gap-4 mb-6">
          <div>
            <h3 class="text-xl font-semibold mb-2">Information sur la commande</h3>
            <div class=" grid lg:gap-2 gap-2 lg:grid-cols-1 md:grid-cols-1 text-gray-800">
              <div>
                <span class="dashboard-label">Reference de commande:</span>{{ order.reference }}
              </div>
              <div>
                <span class="dashboard-label">Statut:</span>
                <OrderStatusBadge :status="order.status"/>
              </div>
            </div>
          </div>
          <div>
            <h3 class="text-xl font-semibold mb-2">Information sur la transaction</h3>
            <div class=" grid lg:gap-2 gap-2  lg:grid-cols-1 md:grid-cols-1 text-gray-800">
              <div>
                <span class="dashboard-label">Prix total:</span>
                {{ order.transaction.total | currency }}
              </div>

              <div>
                <span v-if="order.transaction" class="dashboard-label">Taxes:</span>
                {{ order.transaction.tax | currency }}
              </div>
              <div>
                <span class="dashboard-label">Devise:</span><b>{{ order.transaction.currency }}</b>
              </div>
              <div>
                <span class="dashboard-label">Mode de paiement:</span>
                <i>{{ modeText(order.transaction.mode) }}</i>
              </div>
            </div>
            <div>
              <span class="dashboard-label">Statut de la transaction:</span>
              <OrderStatusBadge :status="order.transaction.status"/>
            </div>
          </div>
          <div>
            <h3 class="text-xl font-semibold mb-2">Information du client</h3>
            <div class=" grid lg:gap-2 gap-2  lg:grid-cols-1 md:grid-cols-1 text-gray-800">
              <div>
                <span class="dashboard-label">Nom:</span>
                {{ order.user.lastName }}
              </div>
              <div>
                <span class="dashboard-label">Prénom:</span>
                {{ order.user.firstName }}
              </div>
              <div>
                <span class="dashboard-label">Email:</span>
                {{ order.user.email }}
              </div>
              <div>
                <span class="dashboard-label">Téléphone:</span>
                {{ order.user.phone }}
              </div>
              <div>
                <span class="dashboard-label">Adresse IP:</span>
                {{ order.ipAddress }}
              </div>
              <div>
                <span class="dashboard-label">Inscrit le:</span>
                {{ formatDate(order.user.createdAt, 'MM-DD-YYYY') }}
              </div>
            </div>
          </div>
          <div>
            <h3 class="my-4 text-xl font-semibold">Information de la livraison</h3>
            <div class=" grid grid-cols-1 lg:grid-cols-1 gap-2 text-gray-800">
              <div>
                <span class="dashboard-label">Nom:</span>
                {{ order.deliveryAddress.fullName }}
              </div>
              <div>
                <span class="dashboard-label">Téléphone:</span>
                {{ order.deliveryAddress.phone }}
              </div>
              <div>
                <span class="dashboard-label">Téléphone:</span>
                {{ order.deliveryAddress.address }}
              </div>
              <div>
                <span class="dashboard-label">Région:</span>
                {{ order.deliveryAddress.region }}
              </div>

            </div>
          </div>

        </div>
        <div class="gap-1 mb-6 overflow-y-auto max-h-92">
          <h3 class="mb-1">Articles</h3>
          <div class="grid grid-cols-1 h-3/5">
            <div
                v-for="(item, index) of order.items"
                class="border-b-2 border-t-2 border-gray-200 w-full p-4 rounded-md sm:min-h-32 lg:min-h-20 grid grid-cols-6 hover:shadow-sm"
                :key="index">
              <div class="col-span-1">
                <img :src="item.product.image_url" alt="prod" class="w-12">
              </div>
              <div class="col-span-4 grid grid-cols-1">
                <div class=" text-black font-medium">
                  {{ item.product.name }}
                </div>
                <!--              <div class=" text-black font-thin"-->
                <!--                   v-if="item.variation_name && item.variation_name !== 'undefined' ">-->
                <!--                {{ item.variation_name }}-->
                <!--              </div>-->
                <div class="text-gray-900 font-bold">
                  {{ item.totalPrice | currency }}
                </div>
              </div>
              <div class="col-span-1 text-gray-900 font-medium leading-tight">
                {{ item.quantity }} x {{ item.unitPrice }}
              </div>
            </div>
          </div>
        </div>
        <div class="md:col-span-3">
          <h3 class="text-2xl font-semibold">Changer l'état de la commande</h3>
          <div class="grid gap-6 grid-cols-3 lg:grid-cols-5  col-span-2 mt-6">
            <styled-button name="Complet" button-color="green" @click="updateStatus('COMPLETED')"/>
            <styled-button name="En livraison" button-color="purple"
                           @click="updateStatus('DELIVERING')"/>
            <styled-button name="En traitement" button-color="blue"
                           @click="updateStatus('HANDLING')"/>
            <styled-button name="En attente" button-color="gray" @click="updateStatus('PENDING')"/>
            <styled-button name="Annulé" button-color="red" @click="updateStatus('CANCELED')"/>
          </div>
        </div>
        <div class="md:col-span-3">
          <h3 class="text-2xl font-semibold">Changer l'état de la Transaction</h3>
          <div class="grid gap-6 grid-cols-3 lg:grid-cols-5  col-span-2 mt-6">
            <styled-button name="Complet" button-color="green" @click="updateTransactionStatus('COMPLETED')"/>
            <styled-button name="En attente" button-color="gray" @click="updateTransactionStatus('PENDING')"/>
            <styled-button name="Annulé" button-color="red" @click="updateTransactionStatus('CANCELED')"/>
          </div>
        </div>

      </div>
    </template>

  </Box>
</template>

<script>
import Box from "@/components/Box";
import OrderExtend from "@/mixins/OrderExtend";
import OrderStatusBadge from "@/components/OrderStatusBadge";
import StyledButton from "@/components/StyledButton";
// import CommentSection from "@/components/CommentSection";

export default {
  name: "OrderView",
  components: {
    // CommentSection,
    StyledButton,
    OrderStatusBadge, Box
  },
  mixins: [OrderExtend],
  created() {
    this.getOrder()
  },
  data() {
    return {
      order: {
        transaction: {},
        deliveryAddress: {}
      },
    }
  },
  methods: {
    formatDate(input) {
      return (new Date(input)).toLocaleString()
    },
    async getOrder() {
      const {data, status} = await this.$http.get('order/' + this.$route.params.id);

      if (status === 200) {
        this.order = {...this.order, ...data};
      }
    },
    async updateStatus(status) {
      try {
        const {data} = await this.$http.patch(`order/${this.order._id}/status`, {
          status: status
        })

        if (data) {
          this.order.status = status
          this.$notify({
            group: 'foo',
            title: 'Mise à jour statut',
            text: 'Nouveau statut de la commande: ' + this.statusText(status),
          })
        }
      } catch (e) {
        console.warn(e)
        this.$notify({
          group: 'foo',
          type: 'warn',
          title: 'Mise à jour échoué',
          text: 'Erreur lors de la mise à jour',
        })
      }
    },
    async updateTransactionStatus(status) {
      try {
        const {status: statusCode} = await this.$http.patch(`transaction/${this.order.transaction._id}/status`, {
          status: status
        })

        if (statusCode === 200) {
          this.$set(this.order.transaction, 'status', status)

          this.$notify({
            group: 'foo',
            title: 'Mise à jour statut',
            text: 'Nouveau statut de la commande: ' + this.statusText(status),
          })
        }
      } catch (e) {
        console.warn(e)
        this.$notify({
          group: 'foo',
          type: 'warn',
          title: 'Mise à jour échoué',
          text: 'Erreur lors de la mise à jour',
        })
      }
    },

  }
}
</script>

<style scoped>

.dashboard-label {
  margin-right: 0.5rem;
  font-weight: bold;
}

.edit-icon {
  position: absolute;
  right: 0;
  top: 5px;
}

.show-mode {
  display: inline-block;
  border: 1px solid gray;
  padding: 5px;
  min-width: 100%;
  position: relative;
  height: 40px;
}

.edit-mode {
  display: none;
}

.edit-show_zone:hover > .edit-mode {
  display: block;
}

.edit-show_zone:focus-within > .show-mode {
  display: none;
}

.edit-show_zone:focus-within > .edit-mode {
  display: block;
}

.edit-show_zone:hover > .show-mode {
  display: none;
}

.edit-mode:focus {
  display: block;
}

.edit-mode:focus > .show-mode {
  display: none;
}

</style>
