import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCurrencyFilter from "vue-currency-filter";
import VueMeta from "vue-meta";
import VModal from "vue-js-modal";
import "@/assets/css/tailwind.css";
import Notifications from "vue-notification";
import axios from "axios";
import "@/assets/css/multiselect.css";
import VueFormulate from "@braid/vue-formulate";
import { fr } from "@braid/vue-formulate-i18n";
import Buefy from "buefy";
// import 'buefy/dist/buefy.css'
import VCalendar from "v-calendar";
import "@mdi/font/css/materialdesignicons.css";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";

Vue.use(VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);

Vue.use(Buefy);
Vue.use(VCalendar, {
  // ...some defaults
  screens: {
    tablet: "576px",
    laptop: "992px",
    desktop: "1200px",
  },
  // ...other defaults
});

let baseUrl;

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  baseUrl = "https://api.electroniccorp.sn/api/v1";
} else {
  // baseUrl = "http://api.electroniccorp.test/api/v1";
  baseUrl = "https://api.electroniccorp.sn/api/v1";
}
axios.defaults.baseURL = baseUrl;
Vue.prototype.$baseUrl = baseUrl;

Vue.config.productionTip = false;

Vue.use(VueFormulate, {
  plugins: [fr],
});

Vue.use(VModal, { dynamicDefault: { resizable: true } });
Vue.use(VueMeta);
Vue.use(Notifications);
Vue.use(VueCurrencyFilter, {
  symbol: "FCFA",
  thousandsSeparator: ".",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "back",
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});

axios.interceptors.request.use(
  async (config) => {
    if (store.getters.authStatus) {
      config.headers = {
        Authorization: `Bearer ${store.getters.accessToken}`,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Vue.prototype.$http = axios;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
