<template>
  <div class="container mx-auto ">
    <div class="col-span-3 px-6 my-6">
      <h2 class="text-2xl font-bold text-gray-500 uppercase">Étiquette des produits</h2>
    </div>
    <div class="grid grid-cols-3 bg-white overflow-hidden rounded-lg shadow-md bg-white p-6">

      <div>
        <TagEdit @created="addTag"/>
      </div>
      <div class="col-span-2">
        <TagList :tags="tags" @remove="removeTag"/>
      </div>
    </div>
  </div>
</template>

<script>
import TagList from "@/pages/Product/Tag/List";
import TagEdit from "@/pages/Product/Tag/Edit";

export default {
  name: "TagCompose",
  components: {TagEdit, TagList},
  created() {
    this.getTags();
  },
  data() {
    return {
      tags: []
    };
  },
  methods: {
    async removeTag(tagId) {
      try {
        const {status} = await this.$http.delete('product_tag/' + tagId);

        if (status === 200) {
          const index = this.tags.findIndex(tag => tag._id === tagId);
          this.tags.splice(index, 1);
        }

      } catch (e) {
        console.log(e)
      }
    },
    addTag(attribute) {
      this.tags.push(attribute);
    },
    async getTags() {
      try {
        const {data} = await this.$http.get("product_tag");

        if (data) {
          this.tags = data;
        }
      } catch (e) {
        console.log(e)
      }

    }
  }
};
</script>

<style scoped>

</style>
