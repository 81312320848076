<template>
  <ListView title="Liste Clientèle">
    <template #toolbar>

    </template>
    <div class="container">
      <table>
        <thead>
        <tr>
          <th>
            Code CLient
          </th>
          <th>
            Nom Complet
          </th>
          <th>
            Commandes
          </th>
          <th>
            Dépensés
          </th>
          <th>
            Téléphone
          </th>
          <th>
            Email
          </th>
          <th>
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="customer of customers" :key="customer._id">
          <td>{{ customer.code }}</td>
          <td>{{ customer.user.firstName }} {{ customer.user.lastName }}</td>
          <td>1</td>
          <td>{{ 0 | currency }}</td>
          <td>{{ customer.user.phone }}</td>
          <td>{{ customer.user.email }}</td>
          <td>
            <b-button tag="RouterLink" icon-left="eye" :to="`/dashboard/customer/view/${customer._id}`" label="Voir"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";

export default {
  name: "index",
  components: { ListView},
  created() {
    this.getCustomers()
  },
  data() {
    return {
      customers: []
    };
  },
  methods: {
    async getCustomers() {
      const {data} = await this.$http.get("customer");

      if (data) {
        this.customers = data;
      }
    }
  }
};
</script>

<style scoped>

</style>
