<template>
  <EditForm title="Ajouter un Produit" @submit="submitProduct" :is-update="isUpdate" :errors="errors">
    <div class="container">
      <b-tabs v-model="activeTab" expanded type="is-toggle">
        <b-tab-item label="Informations">
          <div class="grid lg:grid-cols-3 gap-2">
            <div class="col-span-2">
              <b-field label="Nom du produit">
                <b-input v-model="product.name"/>
              </b-field>
            </div>
            <div class="col-span-1">
              <b-field label="SKU">
                <b-input v-model="product.sku"/>
              </b-field>
            </div>
            <div class="col-span-3">
              <label>Description <br>
                <quill-editor
                    ref="myQuillEditor"
                    :content="product.description"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onEditorChange($event)"
                />
              </label>
            </div>
            <div class="col-span-3">
              <b-field label="Courte Description">
                <b-input type="textarea" v-model="product.shortDescription"/>
              </b-field>
            </div>
            <!--            <div class="col-span-3">-->
            <!--              <TextInput placeholder="mot cle, mot cle" label-text="SKU" v-model="product.keywords"/>-->
            <!--            </div>-->
            <div>
              <label class="label">
                <span class="mb-2 block">Catégories</span>
                <Multiselect
                    v-model="product.categories"
                    :preselect-first="true"
                    label="name"
                    track-by="_id"
                    :options="categories"
                    :multiple="true"
                />
              </label>
            </div>
            <div>
              <b-field label="Insérer des étiquettes">
                <b-taginput
                    v-model="product.tags"
                    :data="filteredTags"
                    autocomplete
                    :allow-new="true"
                    :open-on-focus="true"
                    field="name"
                    icon="label"
                    placeholder="Insérer une étiquette"
                    @typing="getFilteredTags"
                    @add="addTag"
                    :before-adding="beforeAddingTag"
                >
                </b-taginput>
              </b-field>
            </div>
            <div>
              <b-field label="Marque">
                <b-select placeholder="Choisir une marque" v-model="product.brand" expanded>
                  <option
                      v-for="(brand, index) in brands"
                      :value="brand._id"
                      :key="index">
                    {{ brand.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div>
              <b-field label="Prix">
                <b-input v-model="product.price"/>
              </b-field>
            </div>
            <div>
              <b-field label="Taxe">
                <b-input v-model="product.tax"/>
              </b-field>
            </div>
            <div>
              <b-field label="Poids">
                <b-input v-model="product.weight"/>
              </b-field>
            </div>
            <div>
              <b-field label="Statut">
                <b-select placeholder="Statut du produit" v-model="product.status" expanded>
                  <option
                      v-for="(status, index) in allStatus"
                      :value="status.id"
                      :key="index">
                    {{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div>
              <b-field label="Quantité maximum achetable">
                <b-input v-model="product.quantityPerSale"/>
              </b-field>
            </div>
            <div>
              <b-field label="Promotion">
                <b-input v-model="product.promotion"/>
              </b-field>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Spécifications">
          <b-collapse
              class="card"
              animation="slide"
              v-for="(detail, index) of product.details"
              :key="index"
              :open="collapsed == index"
              @open="collapsed = index">
            <template #trigger="props">
              <div
                  class="card-header"
                  role="button"
              >
                <p class="card-header-title">
                  {{ product.details[index].name }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                      :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <div class="my-2">
                  <b-field label="Nom de la rubrique">
                    <b-input size="is-small" v-model="product.details[index].name"/>
                  </b-field>
                </div>
                <div class="grid grid-cols-1 gap-4 p-2 border border-gray-200 rounded-sm">
                  <div class="my-2">
                    <h4 class="text-gray-400 text-sm font-light uppercase">Spécifications</h4>
                  </div>
                  <Draggable v-model="product.details[index].specifications">
                    <div class="grid grid-cols-7 gap-4"
                         v-for="(specification, index2) in product.details[index].specifications" :key="index2">
                      <div class="col-span-2">
                        <b-field label="Nom">
                          <b-input size="is-small" v-model="product.details[index].specifications[index2].name"/>
                        </b-field>
                      </div>
                      <div class="col-span-3">
                        <b-field label="Valeur">
                          <b-input size="is-small" v-model="product.details[index].specifications[index2].value"/>
                        </b-field>
                      </div>
                      <div class="col-span-1 flex-col-reverse flex">
                        <div>
                          <b-button size="is-small" icon-left="delete" @click="removeSpecification(index, index2)"/>
                        </div>
                      </div>
                      <div class="flex align-middle items-end">
                        <b-icon icon="menu-swap" size="is-large"/>
                      </div>
                    </div>
                  </Draggable>
                  <div class="my-4">
                    <b-button icon-left="plus" size="is-small" @click="addSpecification(index)"/>
                  </div>
                </div>
                <div class="my-4">
                  <b-button icon-left="delete" @click="removeDetail(index)"/>
                </div>
              </div>
            </div>
          </b-collapse>
          <div class="my-4">
            <b-button size="is-small" class="is-primary" @click="addDetailTopic">Ajouter une rubrique</b-button>
          </div>
        </b-tab-item>
        <b-tab-item label="Images">
          <div class="col-span-3 my-4">
            <div class="grid md:grid-cols-1">
              <div
                  class="container border border-gray-200 min-h-14 block p-6 overflow-y-auto"
              >
                <Draggable tag="div" class="grid md:grid-cols-6 md:gap-2" v-model="thumbnails"
                           @change="changeImageOrder">
                  <div
                      v-for="(thumbnail, index) of thumbnails"
                      :key="index"
                      class="border-3 border-gray-300 relative border border-red-300"
                  >
                    <img
                        class=""
                        :src="thumbnail"
                        alt=""
                    >
                    <div class="block absolute top-0 left-0 right-0 bottom-0 hover:bg-gray-700 opacity-25">
                      <b-button
                          type="is-ghost"
                          class="has-text-primary"
                          @click="removeImage(index)"
                          icon-left="delete"
                      />
                    </div>
                  </div>
                </Draggable>
              </div>
              <div class="flex content-center justify-center mt-10">
                <div>
                  <StyledButton name="Insérer une image" @click="insertImage"/>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="false" label="Variations">
          <div class="col-span-3 my-4">
            <div class="container border border-gray-200 min-h-14 block p-6">
              <h4 class="text-gray-500 font-semibold uppercase">Variations </h4>
              <div class="grid grid-cols-2 gap-2">
                <div class="border-3 border-gray-300 border border-red-300 p-4"
                     v-for="(variation, index) of product.variations"
                     :key="index"
                >
                  <div class="grid grid-cols-3 gap-2">

                    <b-button label="Informations" @click="changeTab(index, 'infos')"/>
                    <b-button label="Images" @click="changeTab(index, 'images')"/>
                    <b-button label="Attributs" @click="changeTab(index, 'attributes')"/>

                  </div>
                  <div class="content">
                    <div v-if="isCurrentTab(index, 'infos')">
                      <div class="grid md:grid-cols-3 gap-2">
                        <div class="col-span-2">
                          <b-field label="Nom">
                            <b-input v-model="product.variations[index].name"/>
                          </b-field>
                        </div>
                        <div>
                          <b-field label="SKU">
                            <b-input v-model="product.variations[index].sku"/>
                          </b-field>
                        </div>
                        <div>
                          <b-field label="Prix">
                            <b-input type="number" v-model="product.variations[index].price"/>
                          </b-field>
                        </div>
                        <div>
                          <b-field label="Taxe">
                            <b-input type="number" v-model="product.variations[index].tax"/>
                          </b-field>
                        </div>
                        <div>
                          <b-field label="Poids">
                            <b-input type="number" v-model="product.variations[index].weight"/>
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="isCurrentTab(index, 'images')">
                      <div class="col-span-3 my-4">
                        <div class="grid md:grid-cols-1">
                          <div
                              class="container border border-gray-200 min-h-14 block p-6 overflow-y-auto"
                          >
                            <Draggable v-model="product.variations[index].images"
                                       tag="div"
                                       class="grid md:grid-cols-8 md:gap-2">
                              <div
                                  v-for="(thumbnail, index) of variationThumbnails[index]"
                                  :key="index"
                                  class="border-3 border-gray-300 relative border border-red-300"
                              >
                                <img
                                    class=""
                                    :src="thumbnail"
                                    alt=""
                                >
                                <div
                                    class="block absolute top-0 left-0 right-0 bottom-0 hover:bg-gray-700 opacity-25"></div>
                              </div>
                            </Draggable>
                          </div>
                          <div class="flex content-center justify-center mt-10">
                            <div>
                              <b-button label="Insérer une image" @click="insertImageTo(index)"/>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div v-else-if="isCurrentTab(index, 'attributes')">
                      <div>
                        <div class=" my-4 h-32">
                          <div class="grid grid-cols-5 gap-2">
                            <div class="col-span-2">
                              <label>Attribut
                                <Multiselect
                                    v-model="product.variations[index].attributes"
                                    :preselect-first="true"
                                    label="name"
                                    track-by="_id"
                                    :options="attributes"
                                    :multiple="false"
                                />
                              </label>
                            </div>
                            <div class="col-span-2">
                              <label>Termes
                                <Multiselect
                                    v-model="product.variations[index].attributes"
                                    :preselect-first="true"
                                    label="name"
                                    track-by="_id"
                                    :options="attributes"
                                    :multiple="true"
                                />
                              </label>
                            </div>

                          </div>

                        </div>
                        <StyledButton name="Nouvel Attribut"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-4">
                <b-button icon-left="plus" @click="addVariation" size="is-small"/>
              </div>
            </div>
            <div v-if="false" class="col-span-3 my-4">
              <div class="container border border-gray-200 min-h-14 block p-6">
                <div class="my-4">
                  <h4 class="text-gray-500 font-semibold uppercase">Composables</h4>
                </div>
                <div class="border border-red-300 p-4">
                  <div class="grid grid-cols-4 gap-2">
                    <div>
                      <label>Attributs
                        <Multiselect
                            v-model="attributes"
                            :preselect-first="true"
                            label="name"
                            track-by="_id"
                            :options="attributes"
                            :multiple="true"
                        />
                      </label>
                    </div>
                    <div>
                      <SelectInput/>
                    </div>

                  </div>
                </div>
                <div class="my-4">
                  <SVGButton class="w-10 h-10">
                    <AddIcon/>
                  </SVGButton>
                </div>
              </div>
            </div>
          </div>

        </b-tab-item>
        <b-tab-item label="Attributs">
          <div class="col-span-4 my-4">

            <div
                v-for="(attribute, index) of product.attributes" :key="index"
                class="grid grid-cols-6 gap-8"
            >
              <div class="col-span-2">
                <b-field label="Attribut">
                  <Multiselect
                      v-model="product.attributes[index].attribute"
                      label="name"
                      track-by="_id"
                      :options="attributes"
                      :multiple="false"
                  />
                </b-field>
              </div>
              <div class="col-span-3"
                   v-if="product.attributes[index].attribute && product.attributes[index].attribute.type === 'SELECTABLE'">
                <b-field label="Termes">
                  <Multiselect
                      v-model="product.attributes[index].terms"
                      :options="product.attributes[index].attribute.terms"
                      label="value"
                      track-by="_id"
                      :multiple="true"
                  />
                </b-field>
              </div>
              <div class="flex flex-col-reverse">
                <div class="flex flex-row justify-end">
                  <b-button icon-left="delete" @click="removeAttribute(index)"/>
                </div>
              </div>
            </div>
            <div class="my-4">
              <b-button icon-left="plus" @click="addAttribute" size="is-small"/>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Additionnelles">
          <div class="col-span-4 my-4">
            <div class="grid grid-cols-1 gap-4">
              <div>
                <b-field label="Achetés avec">
                  <Multiselect
                      v-model="product.buyWith"
                      label="name"
                      track-by="_id"
                      :options="products"
                      :multiple="true"
                  />
                </b-field>
              </div>
              <div>
                <b-field label="Catégorie associé">
                  <Multiselect
                      v-model="product.categoriesWith"
                      label="name"
                      track-by="_id"
                      :options="categories"
                      :multiple="true"
                  />
                </b-field>
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <div>
      </div>
    </div>
  </EditForm>

</template>

<script>
import Vue from "vue";
import EditForm from "@/components/EditForm";
import Multiselect from "vue-multiselect";
import StyledButton from "@/components/StyledButton";
import FileManager from "@/mixins/FileManager";
import SVGButton from "@/components/SVGButton";
import AddIcon from "@/components/icons/AddIcon";
import Draggable from "vuedraggable";
import {quillEditor} from "vue-quill-editor";

import Quill from "quill";
import ImageResize from "quill-image-resize";
import {ImageDrop} from "quill-image-drop-module";
import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import SelectInput from "@/components/SelectInput";
import ProductExtend from "@/mixins/ProductExtend";
import {PRODUCT_STATUS_ACTIVE, PRODUCT_STATUS_DISABLED, PRODUCT_STATUS_SOLD_OUT} from "@/helpers/constants";
import ImageUploader from "quill-image-uploader";

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/ImageDrop", ImageDrop);
Quill.register("modules/imageUploader", ImageUploader);


const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  ["link", "image"],
  [{"header": 1}, {"header": 2}],
  [{"list": "ordered"}, {"list": "bullet"}],
  [{"script": "sub"}, {"script": "super"}],
  [{"indent": "-1"}, {"indent": "+1"}],
  [{"direction": "rtl"}],

  [{"size": ["small", false, "large", "huge"]}],
  [{"header": [1, 2, 3, 4, 5, 6, false]}],

  [{"color": []}, {"background": []}],
  [{"font": []}],
  [{"align": []}],

  ["clean"]
];

export default {
  name: "Create",
  props: {
    isUpdate: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    SelectInput,
    AddIcon, SVGButton, StyledButton, quillEditor, Draggable,
    EditForm, Multiselect
  },
  mounted() {
    console.log("this is current quill instance object", this.$refs.myQuillEditor);
  },
  mixins: [FileManager, ProductExtend],
  created() {
    this.getCategories();
    this.getTags();
    this.getBrands();
    this.getAttributes();
    this.getProducts();

    if (this.isUpdate) {
      this.editForm();
    }
  },
  data() {
    const self = this;

    return {
      filteredTags: [],
      activeTab: 0,
      collapsed: null,
      errors: null,
      allStatus: [
        {
          id: PRODUCT_STATUS_ACTIVE,
          name: "Actif"
        },
        {
          id: PRODUCT_STATUS_SOLD_OUT,
          name: "Rupture"
        },
        {
          id: PRODUCT_STATUS_DISABLED,
          name: "Désactivé"
        }
      ],
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
          ImageDrop: true,
          ImageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"]
          },
          imageUploader: {
            // eslint-disable-next-line no-unused-vars
            upload: async (file) => {
              try {
                const formData = new FormData();
                formData.append('file', file);

                const {data} = await self.$http.post('attachment/editor/upload', formData, {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                });
                return data.data.url;
              } catch (e) {
                console.log(e);
              }
            },
          }

        }
      },
      products: [],
      categories: [],
      tags: [],
      brands: [],
      thumbnails: [],
      variationThumbnails: [],
      variationTabs: [],
      variationCurrentTabs: [],
      attributes: [],
      product: {
        name: "",
        sku: "",
        description: "<h2>Décrivez le produit...</h2>",
        shortDescription: "",
        categories: [],
        attributes: [],
        tags: [],
        brand: "",
        price: 0,
        weight: 0,
        tax: 0,
        quantityPerSale: 5,
        composables: [],
        buyWith: [],
        images: [],
        keywords: '',
        variations: [],
        details: [],
        categoriesWith: [],
        status: PRODUCT_STATUS_ACTIVE,
        promotion: 0,
      }
    };
  },

  methods: {
    addAttribute() {
      this.product.attributes.push({
        attribute: null,
        terms: []
      })
    },
    removeAttribute(index) {
      this.product.attributes.splice(index, 1);
    },
    removeDetail(index) {
      this.product.details.splice(index, 1);
    },
    removeSpecification(index, index2) {
      this.product.details[index].specifications.splice(index2, 1);
    },
    // eslint-disable-next-line no-unused-vars
    async addTag(tag) {

      return false;


    },
    // eslint-disable-next-line no-unused-vars
    beforeAddingTag(tag) {
      this.createTag(tag)
      return false
    },
    // eslint-disable-next-line no-unused-vars
    async createTag(tag) {
      const {data} = await this.$http.post("product_tag", {name: tag});

      if (data) {
        console.log(data)
        this.tags.push(data);
        this.product.tags.push(data);
        // return data;
      }

      return false;
    },
    getFilteredTags(text) {
      this.filteredTags = this.tags.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    async editForm() {
      const {data} = await this.$http.get("/product/by_id/" + this.$route.params.id);
      if (data) {
        this.product = {
          ...this.product,
          ...data,
          brand: data.brand,
          images: data.images.filter(image => image !== null && typeof image !== "undefined" ),
        };

        console.log('init '+ JSON.stringify(data.images_url));
        this.thumbnails = data.images_url ? data.images_url : [];
      }
    },
    addComposable() {
      this.product.composables.push([
        {
          attribute: {},
          modifier: [{
            type: "",
            value: ""
          }]
        }
      ]);
    },
    isCurrentTab(index, tab) {
      return this.variationCurrentTabs[index] === tab;
    },
    changeTab(index, newTab) {
      console.log(index, newTab);

      Vue.set(this.variationCurrentTabs, index, newTab);
      console.log(this.variationCurrentTabs[index]);
    },
    async getProducts() {
      const {data} = await this.$http.get("product/for_admin");

      if (data) {
        this.products = data;
      }
    },
    addVariation() {
      this.variationTabs.push([
        {title: "Informations", value: "infos"},
        {title: "Images", value: "images"},
        {title: "Attributs", value: "attributes"}
      ]);
      this.variationCurrentTabs.push("infos");
      this.variationThumbnails.push([]);
      this.product.variations.push({
        name: "",
        sku: "",
        price: 0,
        taxe: 0,
        weigh: 0,
        images: [],
        attributes: []
      });
    },
    async addSpecification(index) {
      this.product.details[index].specifications.push({
        name: "",
        value: ""
      });
    },
    async addDetailTopic() {
      this.product.details.push({
        name: "Nouvelle rubrique",
        specifications: []
      });
    },
    async submitProduct() {
      try {
        if (!this.isUpdate) {
          const {data} = await this.$http.post("product", {
            ...this.product,
            attributes: this.product.attributes.map(attribute => {
              return {
                ...attribute,
                attribute: attribute.attribute._id
              }
            })
          });

          if (data) {
            await this.$router.push(`/dashboard/product/view/${data.slug}`);
          }
        } else {
          const {data} = await this.$http.put(`product/${this.$route.params.id}`, {
            ...this.product,
            attributes: this.product.attributes.map(attribute => {
              console.log(attribute._id)
              return {
                ...attribute,
                attribute: attribute.attribute._id
              }
            })
          });

          if (data) {
            await this.$router.push(`/dashboard/product/view/${data.slug}`);
          }
        }
      } catch (e) {
        console.error(e)
        if (e.response.data) {
          this.errors = {
            tilte: e.response.data.error,
            message: e.response.data.message
          };
        }

      }
    },
    async getCategories() {
      const {data} = await this.$http.get("product_category/last_level");

      if (data) {
        this.categories = data;
      }
    },
    async getAttributes() {
      const {data} = await this.$http.get("attribute");

      if (data) {
        this.attributes = data;
      }
    },
    async getTags() {
      const {data} = await this.$http.get("product_tag");

      if (data) {
        this.tags = data;
        this.filteredTags = data;
      }
    },
    async getBrands() {
      const {data} = await this.$http.get("product_brand");

      if (data) {
        this.brands = data
      }
    },
    changeImageOrder({moved}) {
      const value = this.product.images.splice(moved.oldIndex, 1)[0];
      this.product.images.splice(moved.newIndex, 0, value);
    },
    removeImage(index) {
      this.thumbnails.splice(index, 1);
      this.product.images.splice(index, 1);
    },
    insertImage() {
      const self = this;
      this.showFileManager(payload => {
        console.log("payload " + JSON.stringify(self.product.images))
        self.thumbnails = [...self.thumbnails, payload.link];
        self.product.images = [...self.product.images, payload.selected];
      }, true);
    },
    insertImageTo(index) {
      const self = this;
      this.showFileManager(payload => {
        Vue.set(self.variationThumbnails, index, payload.link);
        self.product.variations[index].images = payload.selected;
      }, true);
    },
    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
      // console.log("editor blur!", quill);
    },
    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
      // console.log("editor focus!", quill);
    },
    // eslint-disable-next-line no-unused-vars
    onEditorReady(quill) {
      // console.log("editor ready!", quill);
    },
    // eslint-disable-next-line no-unused-vars
    onEditorChange({quill, html, text}) {
      // console.log("editor change!", quill, html, text);
      this.product.description = html;
    },
  }
};
</script>

<style lang="scss">

.tabs {
  position: relative;
  margin: 0 auto;

  &__active-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: black;
    transition: transform 0.4s ease, width 0.4s ease;
  }

  &__item {
    display: inline-block;
    margin: 0 5px;
    padding: 10px 10px 8px;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: gray;
    text-decoration: none;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.25s;

    &_active {
      color: black;
    }

    &:hover {
      border-bottom: 2px solid gray;
      color: black;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid gray;
      color: black;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
