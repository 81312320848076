export default {
  name: "OrderExtend",
  methods: {
    modeText(input) {
      switch (input) {
        case "AT_DELIVERY":
          return "à la livraison";
        case "ONLINE":
          return "en ligne";
      }
    },
    statusText(input) {
      switch (input) {
        case "CANCELED":
          return "annulé";
        case "COMPLETED":
          return "complet";
        case "DELIVERING":
          return "en livraison";
        case "PROCESSING":
        case "HANDLING":
          return "en traitement";
        case "PENDING":
          return "en attente";
        default:
          return input;
      }
    },
    statusColor(input) {
      switch (input) {
        case "CANCELED":
          return "red";
        case "COMPLETED":
          return "green";
        case "DELIVERING":
          return "purple";
        case "HANDLING":
        case "PROCESSING":
          return "blue";
        case "PENDING":
          return "gray";
        default:
          return "gray";
      }
    },
  },
};
