<template>
    <div class="container h-full px-6">
        <div class="my-8 bg-white rounded-lg shadow-sm p-6">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Box"
}
</script>

<style scoped>

</style>
