import Router from "vue-router";
import store from "@/store";

import Dashboard from "@/components/Dashboard";
import DashboardHome from "@/pages/Home";
import ArticleListPage from "@/pages/Article";
import ArticleViewPage from "@/pages/Article/View";
import ArticleCreatePage from "@/pages/Article/Create";
import BannerListPage from "@/pages/Banner";
import BannerEditPage from "@/pages/Banner/Edit";
import CustomerListPage from "@/pages/Customer";
import CustomerViewPage from "@/pages/Customer/View";
import MenuListPage from "@/pages/Menu";
import MenuCreatePage from "@/pages/Menu/Create";
import OrderListPage from "@/pages/Order";
import OrderViewPage from "@/pages/Order/View";
import ProductListPage from "@/pages/Product";
import ProductViewPage from "@/pages/Product/View";
import ProductCreatePage from "@/pages/Product/Create";
import ProductEditPage from "@/pages/Product/Edit";
import AttributeComposePage from "@/pages/Product/Attribute";
import TagGroupComposePage from "@/pages/Product/TagGroup";
import BrandComposePage from "@/pages/Product/Brand";
import ArticleCategoryComposePage from "@/pages/Article/Category";
import CategoryComposePage from "@/pages/Product/Category";
import TagComposePage from "@/pages/Product/Tag";
import TransactionListPage from "@/pages/Transaction";
import TransactionViewPage from "@/pages/Transaction/View";
import UserListPage from "@/pages/User";
import UserViewPage from "@/pages/User/View";
import UserCreatePage from "@/pages/User/Create";
import SettingPage from "@/pages/Setting";
import NotificationPage from "@/pages/Notification/Send";

import Vue from "vue";
import LoginPage from "@/pages/Login";
import LogoutComponent from "@/components/Logout";

const routes = [
  { path: "/", redirect: { name: "DashboardHome" } },
  { path: "/login", component: LoginPage },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      { path: "/", redirect: { name: "DashboardHome" } },
      { path: "home", name: "DashboardHome", component: DashboardHome },
      { path: "article/list", name: "ArticleList", component: ArticleListPage },
      {
        path: "article/view/:id",
        name: "ArticleView",
        component: ArticleViewPage,
      },
      {
        path: "article/create",
        name: "ArticleCreate",
        component: ArticleCreatePage,
      },
      {
        path: "article/edit/:id",
        name: "ArticleEdit",
        component: ArticleCreatePage,
        props: {
          isUpdate: true,
        },
      },
      {
        path: "banner/list",
        name: "BannerListePage",
        component: BannerListPage,
      },
      {
        path: "banner/create",
        name: "BannerEditPage",
        component: BannerEditPage,
      },
      {
        path: "banner/edit/:id",
        name: "BannerEditPage",
        component: BannerEditPage,
        props: {
          isUpdate: true,
        },
      },
      {
        path: "article/category",
        name: "ArticleCategoryCompose",
        component: ArticleCategoryComposePage,
      },
      {
        path: "customer/list",
        name: "CustomerList",
        component: CustomerListPage,
      },
      {
        path: "customer/view/:id",
        name: "CustomerView",
        component: CustomerViewPage,
      },
      { path: "menu/list", name: "MenuList", component: MenuListPage },
      { path: "menu/create", name: "MenuCreate", component: MenuCreatePage },
      {
        path: "menu/edit/:id",
        name: "MenuEdit",
        component: MenuCreatePage,
        props: {
          isUpdate: true,
        },
      },
      { path: "order/list", name: "OrderList", component: OrderListPage },
      { path: "order/view/:id", name: "OrderView", component: OrderViewPage },
      { path: "product/list", name: "ProductList", component: ProductListPage },
      {
        path: "product/view/:id",
        name: "ProductView",
        component: ProductViewPage,
      },
      {
        path: "product/create",
        name: "ProductCreate",
        component: ProductCreatePage,
      },
      {
        path: "product/edit/:id",
        name: "ProductEdit",
        component: ProductCreatePage,
        props: {
          isUpdate: true,
        },
      },
      {
        path: "product/attribute",
        name: "AttributeCompose",
        component: AttributeComposePage,
      },
      {
        path: "product/tag_group",
        name: "TagGroup",
        component: TagGroupComposePage,
      },
      {
        path: "product/brand",
        name: "BrandCompose",
        component: BrandComposePage,
      },
      {
        path: "product/category",
        name: "CategoryCompose",
        component: CategoryComposePage,
      },
      {
        path: "product/tag",
        name: "TagCompose",
        component: TagComposePage,
      },
      {
        path: "product/edit/:id",
        name: "ProductEdit",
        component: ProductEditPage,
      },
      {
        path: "transaction/list",
        name: "TransactionList",
        component: TransactionListPage,
      },
      {
        path: "transaction/view/:id",
        name: "TransactionView",
        component: TransactionViewPage,
      },
      { path: "user/list", name: "UserList", component: UserListPage },
      { path: "user/view/:id", name: "UserView", component: UserViewPage },
      { path: "user/create", name: "UserCreate", component: UserCreatePage },
      {
        path: "user/edit/:id",
        name: "UserEdit",
        component: UserCreatePage,
        props: {
          isUpdate: true,
        },
      },
      {
        path: "setting",
        name: "SettingPage",
        component: SettingPage,
      },
      {
        path: "notification",
        name: "NotificationPage",
        component: NotificationPage,
      },
    ],
  },
  { path: "/logout", component: LogoutComponent },
];

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.authStatus) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
