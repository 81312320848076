<template>
  <Box>
    <section class="container mx-auto">
      <div class="grid-cols-6 grid">
        <div class="lg:col-span-6">
          <b-field label="Arrêté toutes les commandes">
            <b-switch :value="metadata('STOP_ORDER')" size="is-medium"
                      @input="$emit('change','STOP_ORDER', $event)"/>
          </b-field>
          <b-field label="Arrêté les commandes en ligne">
            <b-switch :value="metadata('STOP_ONLINE_ORDER')" size="is-medium"
                      @input="$emit('change','STOP_ONLINE_ORDER', $event)"/>
          </b-field>
          <b-field label="Arrêté les commandes hors ligne">
            <b-switch :value="metadata('STOP_OFFLINE_ORDER')" size="is-medium"
                      @input="$emit('change','STOP_OFFLINE_ORDER', $event)"/>
          </b-field>
        </div>
      </div>
    </section>
  </Box>
</template>

<script>
import Box from "@/components/Box";

export default {
  name: "OrderSetting",
  components: {Box},
  props: {
    settings: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    metadata() {
      return function (key) {
        const settings = this.settings.filter(setting => setting.key === key)

        if (settings.length) {
          return settings[0].metadata;
        }

        return null
      }
    }
  },
  data() {
    return {
      calculationType: [
        {name: "% | Variable", value: "VARIABLE"},
        {name: "CFA | Fixe", value: "FIXED"},
      ]
    }
  },
}
</script>

<style scoped>

select {
  margin-top: 0;
}

</style>
