<template>
  <ListView title="Liste des Produits">
    <template #toolbar>
      <div class="grid md:grid-cols-5 gap-2">
        <div>
          <LinkButton label="Nouvel Article" to="/dashboard/article/create"/>
        </div>
        <div>
          <LinkButton label="Gérer les Catégories" to="/dashboard/article/category"/>
        </div>
      </div>
    </template>
    <div class="container bg-white min-h-64 rounded-md shadow-sm p-6">
      <b-table
          :data="articles"
          :hoverable="true"
          :loading="false"
          :focusable="false"
          :mobile-cards="false"
          paginated
          :per-page="20"
      >
        <b-table-column label="" width="40"
                        v-slot="props">
          <b-image :src="props.row.image_url" :alt="props.row.slug" class="w-10 h-10"/>
        </b-table-column>
        <b-table-column field="title" label="Titre de l'article" width="300" searchable
                        v-slot="props">
          {{ props.row.title }}
        </b-table-column>
        <b-table-column field="name" label="Statut" width="80"
                        v-slot="props">
          <ProductStatusBadge :status="props.row.status"/>

        </b-table-column>
        <b-table-column field="name" label="Actions" width="250"
                        v-slot="props">
          <div class="grid grid-cols-2 gap-2">
            <b-button
                size="is-small"
                icon-left="lead-pencil"
                tag="router-link"
                :to="`/dashboard/article/edit/${props.row._id}`"/>
            <b-button
                size="is-small"
                icon-left="delete"
                @click="remove(props.row._id)"/>
          </div>
        </b-table-column>

      </b-table>
    </div>
  </ListView>

</template>

<script>
import ListView from "@/components/ListView";
import LinkButton from "@/components/LinkButton";
import ProductStatusBadge from "@/components/ProductStatusBadge";
import FileHandler from "@/components/file-manager/FileHandler";

export default {
  name: "index",
  components: {ProductStatusBadge, LinkButton, ListView},
  mixins: [FileHandler],
  created() {
    this.getArticles();
  },
  data() {
    return {
      articles: []
    };
  },
  methods: {
    async getArticles() {
      const {data} = await this.$http.get("article");

      if (data) {
        this.articles = data.data;
      }
    },
    async remove(articleId, index) {
      const {status} = await this.$http.delete("article/" + articleId);

      if (status === 200) {
        this.articles.splice(index, 1);
      }
    }
  }
};
</script>

<style scoped>

</style>
