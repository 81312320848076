<template>
  <EditForm title="Envoyer une notification" @submit="submitNotification" :errors="errors">
    <div class="container px-10">
      <div class="grid lg:grid-cols-1 gap-10">
        <div>
          <b-field label="Titre">
            <b-input v-model="notification.title"/>
          </b-field>
        </div>
        <div>
          <b-field label="Message">
            <b-input type="textarea"  v-model="notification.message"/>
          </b-field>
        </div>
        <div>
          <b-field label="Envoyer au canal">
            <b-select v-model="notification.chanel">
              <option value="MAIN">Tout le monde</option>
              <option value="CUSTOMERS">Clientèle</option>
            </b-select>
          </b-field>

        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "../../components/EditForm";

export default {
  name: "Notification",
  components: {EditForm},
  data() {
    return {
      errors: null,
      notification: {
        title: '',
        message: '',
        image: '',
        chanel: 'MAIN'
      }
    }
  },
  methods: {
    async submitNotification() {
      try {
        await this.$http.post("notification", {
          ...this.notification,
        });

        this.notification = {
          title: '',
          body: '',
          image: ''
        };

        this.$buefy.snackbar.open({
          message: 'Notification envoyer avec succes.',
          type: 'is-success'
        });

      } catch (e) {
        this.$buefy.snackbar.open({
          message: 'Erreur la notification n\'a ete envoyer.',
          type: 'is-danger'
        });
      }


    }
  }
}
</script>

<style scoped>

</style>