<template>
  <EditForm title="Créer une bannière" @submit="submitBanner" :is-update="isUpdate" :errors="errors">
    <div class="container mx-auto">
      <div class="grid grid-cols-3 gap-16">
        <div>
          <div class="grid grid-cols-1">
            <div>
              <b-field label="Nom de la bannière">
                <b-input expanded v-model="banner.name"/>
              </b-field>
            </div>
            <div>
              <b-field label="Emplacement">
                <b-select placeholder="Emplacement de la bannière" v-model="banner.zone" expanded>
                  <option
                      v-for="(zone, index) in zones"
                      :value="zone.value"
                      :key="index">
                    {{ zone.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="my-6">
              <b-button icon-left="plus" @click="addElement">Ajouter un élément</b-button>
            </div>
          </div>
        </div>
        <div class="md:col-span-2">
          <div class="h-96 overflow-y-auto border border-gray-200" style="height: 30rem;">
            <div v-for="(element, index) in banner.elements" :key="index" class="p-8">
              <div>
                <b-field label="Appel">
                  <b-select placeholder="Type de appel" v-model="banner.elements[index].linkType">
                    <option
                        v-for="(linkType, index) in linkTypes"
                        :value="linkType.value"
                        :key="index">
                      {{ linkType.name }}
                    </option>
                  </b-select>
                  <b-select v-if="banner.elements[index].linkType === 'FUNCTION_CALL'" placeholder="Fonction"
                            v-model="banner.elements[index].link" expanded>
                    <option
                        v-for="(callableFunction, index) in callableFunctions"
                        :value="callableFunction.value"
                        :key="index">
                      {{ callableFunction.name }}
                    </option>
                  </b-select>
                  <b-input v-else expanded v-model="banner.elements[index].link"/>
                </b-field>
              </div>
              <div>
                <b-field label="Description">
                  <b-input type="textarea" expanded v-model="banner.elements[index].description"/>
                </b-field>
              </div>
              <div class="grid grid-cols-1">
                <div class="my-4">
                  <div class="buttons">
                    <b-button @click="insertImage(index)">Insérer une image</b-button>
                    <b-button icon-left="delete" @click="removeElement(index)"/>
                  </div>
                </div>
                <div class="my-4 col-span-3">
                  <b-image :key="`image_${index}`" :src="thumbnails[index]" class="w-64" alt=""/>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import FileManager from "@/mixins/FileManager";
// import Draggable from "vuedraggable";

export default {
  name: "Create",
  components: {
    EditForm,
    // Draggable
  },
  mixins: [FileManager],
  props: {
    isUpdate: {
      type: Boolean,
      default: () => false
    }
  },
  created() {
    if (this.isUpdate) {
      this.editForm();
    }
  },
  data() {
    return {
      errors: null,
      linkTypes: [
        {
          name: "Lien interne",
          value: 'LOCAL_URL'
        },
        {
          name: "Lien externe",
          value: 'EXTERNAL_URL'
        },
        {
          name: "Appel de fonction",
          value: 'FUNCTION_CALL'
        },
      ],
      callableFunctions: [
        {
          name: "Newsletter",
          value: 'OPEN_NEWSLETTER_MODAL'
        },
      ],
      zones: [
        {
          name: "Accueil: Principal",
          value: "HOME_MAIN"
        },
        {
          name: "Accueil: Secondaire",
          value: "HOME_SECOND"
        },
        {
          name: "Accueil: Section",
          value: "HOME_SECTION"
        }
      ],
      thumbnails: [],
      banner: {
        name: '',
        zone: '',
        elements: [],
      }
    }
  },
  methods: {
    async editForm() {
      const loadingComponent = this.$buefy.loading.open({})

      try {
        const {data, status} = await this.$http.get('banner/by_id/' + this.$route.params.id)

        if (status === 200) {
          // eslint-disable-next-line no-unused-vars
          const {elementsWithImage, ...bannerData} = data;

          this.banner = {
            ...this.banner,
            ...bannerData,
            elements: data.elements.map(element => {
              return {
                ...element,
                image: element.image._id
              }
            })
          }

          this.thumbnails = data.elementsWithImage.map(element => element.imageUrl)

        }
      } catch (e) {
        console.log(e)
      }
      loadingComponent.close()
    },
    async submitBanner() {
      this.isLoading = true;
      try {
        if (!this.isUpdate) {
          const {status} = await this.$http.post('banner', {
            ...this.banner,
            elements: this.banner.elements.map(banner => {
              return {
                ...banner,
              }
            })
          });

          if (status === 201) {
            await this.$router.push('/dashboard/banner/list');
          }
        } else {
          const {status} = await this.$http.put('banner/' + this.$route.params.id, {
            ...this.banner,
            elements: this.banner.elements.map(banner => {
              return {
                ...banner,
              }
            })
          });

          if (status === 200) {
            await this.$router.push('/dashboard/banner/list');
          }
        }

      } catch (e) {
        this.errors = {
          tilte: e.response.data.error,
          message: e.response.data.message
        };
      }
      this.isLoading = false;
    },
    insertImage(index) {
      const self = this;
      this.showFileManager(payload => {
        if (payload.selected) {
          this.$set(self.thumbnails, index, payload.link);
          self.banner.elements[index].image = payload.selected;
        }
      });
    },
    addElement() {
      this.banner.elements.push({
        link: '',
        description: '',
        image: '',
        linkType: '',
      })
      this.thumbnails.push('')
    },
    removeElement(index) {
      this.thumbnails.splice(index, 1);
      this.banner.elements.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
