<template>
    <label class="block mb-4 text-sm">
        <span class="label">{{ labelText }}</span>
        <input
            :type="inputType"
            class="block px-2 h-10 border rounded w-full mt-1 text-sm border-red-300 focus:border-red-600 focus:outline-none focus:shadow-outline-red form-input"
            :placeholder="placeholder"
            :value="value"
            @input="$emit('input', $event.target.value)"
            @change="$emit('change', $event.target.value)"
            @keydown="$emit('keypressed')"
        />
        <span class="input-error" v-if="error">{{ error }}</span>
    </label>
</template>

<script>
export default {
    name: "TextInput",
    props: {
        inputType: {
            type: [String, Number],
            default: 'text'
        },
        labelText: {
            type: String
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: String,
            default: ''
        },
        error: {
            type: String
        }
    }
}
</script>

<style scoped>



</style>
