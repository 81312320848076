<template>
  <EditForm title="Nouveau Menu" :errors="errors" :is-processing="isLoading" :is-update="isUpdate" @submit="submitMenu">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 my-8">
        <div class="col-span-2">
          <div class="grid grid-cols-1 gap-4">
            <div>
              <b-field label="Nom du menu">
                <b-input expanded v-model="menu.name"/>
              </b-field>
            </div>
            <div>
              <b-field label="Clé">
                <b-input expanded v-model="menu.key"/>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class=" grid xl:grid-cols-6 grid-cols-1 gap-10">
        <div class="xl:col-span-3"
             v-for="(element, index) in menu.elements"
             :key="index"
        >
          <div class="border border-gray-200 p-8 rounded-sm my-10">
            <div>
              <b-field label="Template">
                <b-select size="is-small" placeholder="Choisir un template" expanded
                          v-model="menu.elements[index].template">
                  <option
                      v-for="option in templates"
                      :value="option.template"
                      :key="option.template">
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Titre">
                <b-input size="is-small" expanded v-model="menu.elements[index].title"/>
              </b-field>
              <b-field label="Sous titre">
                <b-input size="is-small" expanded v-model="menu.elements[index].subTitle" type="textarea"/>
              </b-field>
              <b-field label="Description">
                <b-input size="is-small" expanded v-model="menu.elements[index].description" type="textarea"/>
              </b-field>
              <b-field label="URL voir plus">
                <b-input type="text" size="is-small" expanded v-model="menu.elements[index].link"/>
              </b-field>
              <b-field label="Type d'element">
                <b-select size="is-small" placeholder="Choisir" expanded v-model="menu.elements[index].elementType">
                  <option
                      v-for="option in elementType"
                      :value="option.type"
                      :key="option.type">
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Nombre d'élèment limite">
                <b-numberinput size="is-small" expanded v-model="menu.elements[index].limit"/>
              </b-field>
              <b-field v-if="menu.elements[index].elementType === 'PRODUCTS'" label="Produits">
                <Multiselect
                    v-model="menu.elements[index].products"
                    label="name"
                    track-by="_id"
                    :options="products"
                    :multiple="true"
                />
              </b-field>
              <b-field v-if="menu.elements[index].elementType === 'CATEGORY'" label="Catégories">
                <Multiselect
                    v-model="menu.elements[index].category"
                    label="name"
                    track-by="_id"
                    :options="categories"
                    :multiple="false"
                />
              </b-field>
              <b-field label="Couleur" class="my-8">
                <color-picker
                    theme="light"
                    :color="menu.elements[index].color"
                    :sucker-hide="true"
                    :sucker-canvas="suckerCanvas"
                    :sucker-area="suckerArea"
                    @changeColor="changeColor($event, index)"
                    @openSucker="openSucker"
                    style="width: 100% !important;"
                />
              </b-field>
              <div class="grid grid-cols-1">
                <div class="my-4 flex flex-row justify-center">
                  <div class="buttons">
                    <b-button @click="insertImage(index)">Insérer une image</b-button>
                  </div>
                </div>
                <div class="my-4 col-span-3">
                  <b-image :key="`image_${index}`" :src="thumbnails[index]" class="w-64" alt=""/>
                </div>
              </div>
              <div class="my-4">
                <b-button icon-left="delete" @click="removeElement(index)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <b-button
            size="is-small"
            icon-left="plus"
            label="Ajouter une section"
            @click="addElement"/>
      </div>
    </div>

  </EditForm>

</template>

<script>
import EditForm from "@/components/EditForm";
import Multiselect from "vue-multiselect";
import colorPicker from '@caohenghu/vue-colorpicker'
import FileManager from "@/mixins/FileManager";


export default {
  name: "Create",
  components: {
    EditForm,
    Multiselect,
    colorPicker
  },
  mixins: [FileManager],
  props: {
    isUpdate: {
      type: Boolean,
      default: () => false
    }
  },
  created() {
    this.getProducts();
    this.getBanners();
    this.getCategories();
    this.getTags();
    if (this.isUpdate) {
      this.editForm();
    }
  },
  data() {
    return {
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      errors: null,
      isLoading: false,
      elementType: [
        {name: "Produits", type: "PRODUCTS"},
        {name: "Catégories", type: "CATEGORY"},
        {name: "Etiquette", type: "TAG"},
        {name: "Bannière", type: "BANNER"},
      ],
      templates: [
        {name: "Home Expo A", template: "HomeExpoA"},
        {name: "Home Expo B", template: "HomeExpoB"},
        {name: "Bannière 1 Ligne", template: "Banner1Row"},
        {name: "Bannière 2 Colonnes", template: "Banner2Column"},
      ],
      thumbnails: [],
      products: [],
      categories: [],
      tags: [],
      banners: [],
      menu: {
        key: '',
        elements: []
      },
    }
  },
  methods: {
    async editForm() {
      const loadingComponent = this.$buefy.loading.open({})

      try {
        const {data, status} = await this.$http.get('menu/by_id/' + this.$route.params.id)

        if (status === 200) {
          // eslint-disable-next-line no-unused-vars
          const {elementsWithImage, ...menuData} = data;

          this.menu = {
            ...this.menu,
            ...menuData,
            elements: menuData.elements.map(element => {
              return {
                ...element,
                image: element.image ? element.image._id : null
              }
            })
          }
          this.thumbnails = data.elementsWithImage.map(element => element.imageUrl)

        }
      } catch (e) {
        console.log(e)
      }
      loadingComponent.close()
    },
    async submitMenu() {
      this.isLoading = true;
      try {
        const menuData = {
          ...this.menu,
          elements: this.menu.elements.map(element => {
            let category;

            // console.log(element)
            //
            // if (element.elementType === 'CATEGORY' && typeof element === 'object') {
            //   // category = element.category._id;
            // } else {
            //   throw new Error(`Un élément de type catégorie n'a pas été assigné.`)
            // }

            return {
              ...element,
              image: element.image,
              category
            }
          })
        }
        if (!this.isUpdate) {
          const {status} = await this.$http.post('menu', menuData);

          if (status === 201) {
            await this.$router.push('/dashboard/menu/list');
          }
        } else {
          const {status} = await this.$http.put(`menu/${this.menu._id}`, menuData);

          if (status === 200) {
            await this.$router.push('/dashboard/menu/list');
          }
        }

      } catch (e) {
        this.errors = {
          tilte: e.response.data.error,
          message: e.response.data.message
        };
      }

      this.isLoading = false;
    },
    changeColor(color, index) {
      const {r, g, b, a} = color.rgba
      this.menu.elements[index].color = `rgba(${r}, ${g}, ${b}, ${a})`
    },
    openSucker(isOpen) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
    addElement() {
      this.menu.elements.push({
        template: "HomeExpoA",
        title: "",
        description: "",
        link: "",
        elementType: "PRODUCTS",
        limit: 4,
        color: '#59c7f9'
      })
    },
    removeElement(index) {
      this.menu.elements.splice(index, 1);
    },
    async getBanners() {
      try {
        const {data} = await this.$http.get('banner');

        if (data) {
          this.banners = data
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getTags() {
      try {
        const {data} = await this.$http.get('product_tag')

        if (data) {
          this.tags = data
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getCategories() {
      try {
        const {data} = await this.$http.get('product_category')

        if (data) {
          this.categories = data
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getProducts() {
      try {
        const {data, status} = await this.$http.get('product/for_admin')

        if (status === 200) {
          this.products = data
        }
      } catch (e) {
        console.error(e);
      }
    },
    insertImage(index) {
      const self = this;
      this.showFileManager(payload => {
        if (payload.selected) {
          this.$set(self.thumbnails, index, payload.link);
          self.menu.elements[index].image = payload.selected;
        }
      });
    },
  }
};
</script>

<style scoped>

</style>
