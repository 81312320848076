<template>
    <button
        type="button"
        @click="$emit('click')"
        class=""
    >
        <slot/>
    </button>
</template>

<script>

export default {
    name: "SVGButton",
}
</script>

<style scoped>

</style>
