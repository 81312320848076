const persistence = JSON.parse(localStorage.getItem("authentication"));

export default {
  state: {
    accessToken: persistence ? persistence.token : false,
    isAuthenticated: persistence ? persistence.isAuthenticated : false,
  },
  mutations: {
    SET_AUTH(state, payload) {
      state.accessToken = payload.token,
      state.isAuthenticated = payload.isAuthenticated;
      state.user = payload.user;
    },
    LOGOUT(state) {
      state.isAuthenticated = false;
      state.user = {};
    },
  },
  actions: {
    SetAuth(context, payload) {
      return new Promise((resolve) => {
        localStorage.setItem("authentication", JSON.stringify(payload));
        context.commit("SET_AUTH", payload);
        resolve();
      });
    },
    SetLogout({ commit }) {
      return new Promise((resolve) => {
        commit("LOGOUT");
        resolve();
      });
    },
  },
  getters: {
    authStatus: (state) => state.isAuthenticated,
    accessToken: (state) => state.accessToken,
  },
};
