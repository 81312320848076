<template>
  <ListView title="">
    <table>
      <thead>
      <tr>
        <th>Nom</th>
      </tr>
      </thead>
      <draggable v-model="tagGroups" tag="tbody">
<!--        <transition-group tagGroup="tbody">-->
          <tr v-for="tagGroup of tagGroups" :key="tagGroup._id">
            <td>{{ tagGroup.name }}</td>
          </tr>
<!--        </transition-group>-->
      </draggable>
    </table>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";
import Draggable from "vuedraggable";

export default {
  name: "TagGroupList",
  components: { ListView, Draggable },
  props: {
     tagGroups: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>

<style scoped>

</style>
