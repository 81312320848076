<template>
    <router-link
        :class="buttonColorClass"
        class="px-4 py-2 text-sm text-center font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none"
        :to="to"
    >
      {{ label }}
    </router-link>
</template>

<script>
export default {
    name: "LinkButton",
    props: {
        to: {
            type: String,
            default: '/'
        },
        label: {
            type: String,
        },
        buttonColor: {
            type: String,
        }
    },
    computed: {
        buttonColorClass() {
            if (!this.buttonColor)
                return 'bg-red-400 active:bg-red-600 hover:bg-red-500 focus:shadow-outline-red'

            return `bg-${this.buttonColor}-400 active:bg-${this.buttonColor}-600 hover:bg-${this.buttonColor}-500 focus:shadow-outline-${this.buttonColor}`
        }
    }
}
</script>

<style scoped>

</style>
