<template>
    <button
        :class="buttonColorClass"
        class="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none"
        @click="$emit('click')"
        :type="buttonType"
    >
        {{ name }}
    </button>
</template>

<script>
export default {
    name: "StyledButton",
    props: {
        name: {
            type: String,
            required: true
        },
        buttonType: {
            type: String,
            default: 'button'
        },
        buttonColor: {
            type: String,
        }
    },
    computed: {
        buttonColorClass() {
            if (!this.buttonColor)
                return 'bg-red-400 active:bg-red-600 hover:bg-red-500 focus:shadow-outline-red'

            return `bg-${this.buttonColor}-400 active:bg-${this.buttonColor}-600 hover:bg-${this.buttonColor}-500 focus:shadow-outline-${this.buttonColor}`
        }
    }
}
</script>

<style scoped>

</style>
