import { render, staticRenderFns } from "./SVGButton.vue?vue&type=template&id=993756b4&scoped=true&"
import script from "./SVGButton.vue?vue&type=script&lang=js&"
export * from "./SVGButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "993756b4",
  null
  
)

export default component.exports