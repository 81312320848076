<template>
  <ListView title="Commandes">
    <div
        slot="toolbar"
        class="h-full"
    >
      <div class="px-6 container mx-auto">
        <div class="grid gap-6 xl:grid-cols-4 grid-cols-1 my-10">
          <div>
            <b-button
                type="is-primary"
                expanded
                @click="range = {start: currentDate, end: currentDate}"
                label="Aujourd'hui"
            />
          </div>
          <div>
            <b-button
                type="is-primary"
                expanded
                @click="range = {start: new Date(currentDate.setDate(currentDate.getDate() - 1)), end: currentDate}"
                label="Hier"
            />
          </div>
          <div>
            <b-button
                type="is-primary"
                expanded
                @click="range = {
                  start: new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())),
                   end: lastday = new Date((currentDate.setDate(currentDate.getDate() - currentDate.getDay())) - 6)}"
                label="Cette semaine"
            />
          </div>
          <div>
            <b-button
                type="is-primary"
                expanded
                @click="range = {start: new Date(new Date().getFullYear(), new Date().getMonth(), 1), end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)}"
                label="Ce mois ci"
            />
          </div>
          <div class="xl:col-span-4 mb-2">
            <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
                locale="fr"
                @input="rangeChanged"
            >
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="flex flex-col sm:flex-row justify-start items-center">
                  <div class="relative flex-grow">
                    <svg
                        class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                      <path
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                    />
                  </div>
                  <span class="flex-shrink-0 m-2">
              <svg
                  class="w-4 h-4 stroke-current text-gray-600"
                  viewBox="0 0 24 24"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
                  <div class="relative flex-grow">
                    <svg
                        class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                      <path
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                    />
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>


      </div>
    </div>
    <div class="container bg-white min-h-64 rounded-md shadow-sm p-6">
      <b-table
          :data="orders"
          :paginated="isPaginated"
          :per-page="perPage"
          :loading="isLoading"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :pagination-rounded="isPaginationRounded"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :row-class="orderRow"
          aria-next-label="Page suivante"
          aria-previous-label="Page précédente"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column searchable field="reference" label="Reference" width="120" sortable v-slot="props">
          #{{ props.row.reference }}
        </b-table-column>

        <b-table-column field="user.email" searchable label="Client" sortable v-slot="props">
          <div class="flex items-center text-sm">
            <div v-if="props.row.user">
              <p class="font-semibold">
                {{ props.row.user.fullName }}
              </p>
              <p class="text-xs text-gray-600">
                {{ props.row.user.email }}
              </p>
            </div>
            <div v-else>
              <p class="font-semibold">
                Nom manquant
              </p>
              <p class="text-xs text-gray-600">
                Email manquant
              </p>
            </div>
          </div>
        </b-table-column>

        <b-table-column field="transaction.total" label="Prix" sortable v-slot="props">
          {{ props.row.transaction.total | currency }}
        </b-table-column>

        <b-table-column field="status" label="Statut" sortable centered v-slot="props">
          <!--                <span class="tag is-success">-->
          <OrderStatusBadge :status="props.row.status"/>

          <!--                </span>-->
        </b-table-column>
        <b-table-column field="createdAt" label="Date" sortable centered v-slot="props">
          {{ new Date(props.row.createdAt).toLocaleString() }}
        </b-table-column>
        <b-table-column label="Actions" v-slot="props">
          <b-button tag="router-link" icon-left="eye" type="is-primary"
                    :to="`/dashboard/order/view/${props.row._id}`"/>
        </b-table-column>

      </b-table>
    </div>

  </ListView>
</template>

<script>
import OrderStatusBadge from "@/components/OrderStatusBadge";
import ListView from "@/components/ListView";

const orderSound = require("@/assets/audios/liveorder.mp3");

export default {
  name: "Orders",
  components: {ListView, OrderStatusBadge},
  created() {
    this.getOrders();
  },
  mounted() {
    this.eventSource = new EventSource(this.$baseUrl + "/order/listen_orders");

    this.eventSource.onmessage = ({data}) => {
      const parsed = JSON.parse(data);

      const index = this.orders.findIndex(order => order._id === parsed._id);

      if (index !== -1) {
        this.orders.splice(index, 1, parsed);
      } else {
        this.orders.unshift({...parsed, live: true});
        this.playOrderAudio();
      }
    };
  },
  beforeDestroy() {
    this.eventSource.close();
  },
  data() {
    return {
      isLoading: false,
      currentDate: new Date(),
      eventSource: null,
      status: "",
      filter: "",
      masks: {},
      range: {
        start: this.$route.query.start || new Date(),
        end: this.$route.query.end || new Date()
      },
      orders: [],
      isBusy: false,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 15
    };
  },
  watch: {
    "range.start"() {
      this.getOrders();
    },
    "range.end"() {
      this.getOrders();
    }
  },
  methods: {
    rangeChanged() {
      this.$router.push({ path: this.$route.path, query: { end: this.range.end, start: this.range.start } });
    },
    orderRow(row) {
      let result = "";
      if (row.status === "CANCELED") {
        result += "bg-red-100 ";
      }
      if (row.status === "COMPLETED") {
        result += "bg-green-100 ";
      }

      if (row.live) {
        result += "blinking";
      }

      return result;
    },
    playOrderAudio() {
      const audio = new Audio(orderSound);
      audio.play();
    },
    formatDate(input) {
      return new Date(input).toLocaleString();
    },
    async getOrders() {
      this.isLoading = true;
      try {
        const {data, status} = await this.$http.get("order", {
          params: {
            start: this.range.start,
            end: this.range.end
          }
        });

        if (status === 200) {
          this.orders = data.map(order => {
            return {
              transaction: {},
              ...order,
            }
          });
        }
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>

</style>
