<template>
  <EditForm @submit="submitCategory" :is-update="isUpdate"
            :errors="errors">
    <div class="container">
      <div class="grid grid-cols-1 gap-2">
        <div>
          <b-field label="Nom">
            <b-input v-model="category.name"/>
          </b-field>
        </div>
        <div>
          <b-field label="Description">
            <b-input type="textarea" v-model="category.description"/>
          </b-field>
        </div>
        <div v-if="category.level > 1">
          <b-field label="Parent">
            <Multiselect
                v-model="category.parent"
                label="name"
                track-by="_id"
                :options="levelCategories[category.level - 2]"
                :multiple="false"
            />
          </b-field>
        </div>
        <div v-if="thumbnail">
          <b-image :src="thumbnail" alt="preview"/>
        </div>
        <div>
          <b-field class="file is-primary" :class="{'has-name': !!category.banner}">
            <b-upload v-model="category.banner" class="file-label" @input="loadThumbnail" expanded>
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Charger la bannière</span>
            </span>
              <span class="file-name" v-if="category.banner"></span>
            </b-upload>
          </b-field>
        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import Multiselect from "vue-multiselect";

export default {
  name: "CategoryEdit",
  props: {
    categories: {
      type: Array,
      default: () => ([])
    },
    updateCategory: {
      type: Object
    },
    currentState: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Multiselect,
    EditForm
  },
  created() {
    this.getLevels();
  },
  data() {
    return {
      levels: [
        {name: "Menu", value: 1},
        {name: "Titre", value: 2},
        {name: "Catégorie", value: 3}
      ],
      thumbnail: null,
      errors: null,
      isUpdate: false,
      category: {
        name: "",
        parent: null,
        description: '',
        // level: 1,
        order: 0,
        banner: null,
      },
      levelCategories: []
    };
  }
  ,
  methods: {
    loadThumbnail() {
      if (this.category.banner) {
        const reader = new FileReader();
        const self = this;
        reader.onload = function () {

          console.log(reader.result);

          self.thumbnail = reader.result;
        }
        reader.readAsDataURL(this.category.banner);
      }
    },
    async getLevels() {
      try {
        const {data} = await this.$http.get("product_category/levels");

        if (data) {
          this.levelCategories = data;
        }
      } catch (e) {
        console.log(e.response)
      }

    }
    ,
    reset() {
      this.category = {
        title: "",
        parent: null,
        level: 1,
        order: 0
      };

      this.thumbnail = ""

      this.isUpdate = false;
    }
    ,
    async submitCategory() {
      try {
        let payload = {...this.category, level: this.currentState.level};
        const formData = new FormData();

        if (this.currentState.level > 1 && this.currentState.parent) {

          payload.parent = this.currentState.parent;
        }

        for (const key of Object.keys(payload)) {
          if (payload[key]) {
            formData.append(key, payload[key]);
          }
        }

        if (!this.isUpdate) {
          const {data} = await this.$http.post("product_category", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (data) {
            this.$emit("created", {...data, ...payload});
            this.reset();
            await this.getLevels();
          }

        } else {

          const {data} = await this.$http.put("product_category/" + this.category._id, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (data) {
            this.$emit("updated", this.category);
            this.reset();
            await this.getLevels();
          }
        }
      } catch (e) {
        this.errors = {
          tilte: e.response.data.error,
          message: e.response.data.message
        };
      }
    },
    editForm(updateCategory) {

      // eslint-disable-next-line no-unused-vars
      const {banner, bannerImage, children, ...updateData} = updateCategory;
      this.category = updateData;

      if (bannerImage) {
        this.thumbnail = bannerImage
      }
      this.isUpdate = true;
    },
    insertThumbnail() {
      const self = this;
      this.showFileManager(payload => {
        self.thumbnails = payload.link
        self.product.images = payload.selected
      }, true);
    }
  }
};
</script>

<style scoped>

</style>
