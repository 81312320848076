import {
  PRODUCT_STATUS_ACTIVE,
  PRODUCT_STATUS_DISABLED,
  PRODUCT_STATUS_PUBLISHED,
  PRODUCT_STATUS_SOLD_OUT,
} from "@/helpers/constants";

export default {
  name: "NoticeExtend",
  methods: {
    productStatusColor(input) {
      switch (input) {
        case PRODUCT_STATUS_ACTIVE:
          return "green";
        case PRODUCT_STATUS_SOLD_OUT:
          return "orange";
        case PRODUCT_STATUS_DISABLED:
          return "red";
        case PRODUCT_STATUS_PUBLISHED:
          return "blue";
        default:
          return "green";
      }
    },
    productStatusText(input) {
      switch (input) {
        case PRODUCT_STATUS_ACTIVE:
          return "Actif";
        case PRODUCT_STATUS_SOLD_OUT:
          return "Rupture";
        case PRODUCT_STATUS_DISABLED:
          return "Désactivé";
        case PRODUCT_STATUS_PUBLISHED:
          return "Publié";
      }
    },
    noticeStatusColor(input) {
      switch (input) {
        case "pending":
          return "gray";
        case "handling":
          return "blue";
        case "resolved":
          return "green";
        case "critical":
          return "red";
      }
    },
    noticeStatusText(input) {
      switch (input) {
        case "pending":
          return "en attente";
        case "handling":
          return "en traitement";
        case "resolved":
          return "résolu";
        case "critical":
          return "critique";
      }
    },
  },
};
