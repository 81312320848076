<template>
  <Box slot="main">
    <header class="relative mb-20 w-full">
      <div class="absolute top-1 left-1" style="top: 1rem; left: 1rem;">
        <h2 class="text-3xl text-gray-600 font-bold">Fiche de Produit</h2>
      </div>
      <div class="absolute top-1 right-1" style="top: 1rem; right: 1rem;">
        <b-button icon-left="lead-pencil" tag="router-link" icon-pack="mdi"
                  :to="`/dashboard/product/edit/${product._id}`">Éditer
        </b-button>
      </div>
    </header>
    <div class="grid lg:grid-cols-6 sm:gap-2">
      <div class="border border-gray-400 col-span-2">
        <b-carousel :indicator-inside="false" :pause-hover="false">
          <b-carousel-item v-for="(image, i) in product.images_ext"
                           :key="i">
            <b-image class="h-64 object-contain" :src="`${image}.webp`" :webp-fallback="`${product.images[i]}`"></b-image>
          </b-carousel-item>
          <template #indicators="props">
            <b-image class="al image w-10 h-10" :src="`${product.images_ext[props.i]}.webp`"
                     :webp-fallback="`${product.images[props.i]}`"
                     :title="props.i"></b-image>
          </template>
        </b-carousel>
        <!--        <img class="" :src="product.image" alt="">-->
      </div>
      <div class="col-span-4">
        <div class="grid lg:grid-cols-2 gap-2 p-6">
          <div>
            <span class="dashboard-label">Nom:</span>{{ product.name }}
          </div>
          <div>
            <span class="dashboard-label">État:</span>{{ product.status }}
          </div>
          <div>
            <span class="dashboard-label">Prix:</span>{{ product.price | currency }}
          </div>
          <!--          <div>-->
          <!--            <span class="dashboard-label">ID Woocommerce:</span>{{ product.woo_id }}-->
          <!--          </div>-->
          <div>
            <span class="dashboard-label">SKU:</span>{{ product.sku }}
          </div>
          <div>
            <span class="dashboard-label">Catégories:</span>{{ product.categories.map(category =>
            category.name).join(", ") }}
          </div>
          <div>
            <span class="dashboard-label">Étiquettes:</span>{{ product.tags.map(tag =>
            tag.name).join(", ") }}
          </div>
          <div>
            <span class="dashboard-label">Unités vendues:</span>{{ product.unitSold }}
          </div>
          <div>
            <span class="dashboard-label">Montant vendue:</span>{{ product.amount_sold | currency }}
          </div>
          <div>
            <span class="dashboard-label">Lien vers le produit:</span>
            <a :href="`http://electroniccorp.sn/products/${product.slug}`" target="_blank">Ouvrir</a>
          </div>
        </div>
      </div>
    </div>
  </Box>

</template>

<script>
import Box from "@/components/Box";

export default {
  name: "ProductView",
  components: { Box },
  created() {
    this.getProduct();
  },
  data() {
    return {
      product: {
        categories: [],
        tags: []
      }
    };
  },
  methods: {
    async getProduct() {
      try {
        const { data } = await this.$http.get(`/product/${this.$route.params.id}`);

        if (data) {
          this.product = data;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped>

.dashboard-label {
  margin-right: 0.25rem;
  font-weight: 600;

}

.is-active .al img {
  filter: grayscale(0%);
}

.al img {
  filter: grayscale(100%);
}
</style>
