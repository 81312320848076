<template>
  <EditForm title="" @submit="submitTag">
    <div class="container">
      <div class="grid grid-cols-1 gap-2">
        <div>
          <TextInput label-text="Nom" v-model="tag.name" />
        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import TextInput from "@/components/TextInput";

export default {
  name: "AttributeEdit",
  components: { TextInput, EditForm },
  data() {
    return {
      tag: {
        name: "",
      }
    };
  },
  methods: {
    reset() {
      this.tag = {
        title: "",
      };
    },
    async submitTag() {
      const { data } = await this.$http.post("product_tag", this.tag);

      if (data) {
        this.$emit("created", data);
        this.reset()
      }
    }
  }
};
</script>

<style scoped>

</style>
