<template>
  <div class="container mx-auto ">
    <div class="col-span-3 px-6 my-6">
      <h2 class="text-2xl font-bold text-gray-500 uppercase">Groupe</h2>
    </div>
    <div class="grid grid-cols-3 bg-white overflow-hidden rounded-lg shadow-md bg-white p-6">

      <div>
        <TagGroupEdit @created="addTagGroup" />
      </div>
      <div class="col-span-2">
        <TagGroupList :tagGroups="tagGroups" />
      </div>
    </div>
  </div>
</template>

<script>
import TagGroupList from "@/pages/Product/TagGroup/List";
import TagGroupEdit from "@/pages/Product/TagGroup/Edit";

export default {
  name: "TagGroupCompose",
  components: { TagGroupEdit, TagGroupList },
  created() {
    this.getTagGroups();
  },
  data() {
    return {
      tagGroups: []
    };
  },
  methods: {
    addTagGroup(attribute) {
      this.tagGroups.push(attribute);
    },
    async getTagGroups() {
      const { data } = await this.$http.get("product_tag_group");

      if (data) {
        this.tagGroups = data;
      }
    }
  }
};
</script>

<style scoped>

</style>
