<script>
import FileManager from "@/components/file-manager";

export default {
  name: "FileManager",
  methods: {
    showFileManager(eventHandler, isMultiple = false, destination = "thumbnail") {
      this.$modal.show(FileManager, {destination: destination, isMultiple: isMultiple}, {
            resizable: true,
            adaptive: true,
            height: '80%',
            width: '80%',
          },
          {
            'closed': eventHandler
          })
    },
  }
};
</script>

