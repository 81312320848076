<template>
    <div class="bg-white shadow-lg">
        <header class="p-3 text-gray-500 font-bold text-center">Commande du client</header>
        <canvas ref="myChart" :width="width" :height="height"></canvas>
    </div>
</template>

<script>
import Chart from "chart.js";

export default {
    name: "CustomerAmountTimelineChart",
    props: {
        height: {
            type: Number,
            default:
                400
        },
        width: {
            type: Number,
            default:
                400
        },
        labels: {
            type: Array,
            default: () => ([])
        },
        orders: {
            type: Array,
            default: () => ([])
        },
        products: {
            type: Array,
            default: () => ([])
        }
    },
    mounted() {
        const ctx = this.$refs.myChart.getContext('2d');
      // eslint-disable-next-line no-unused-vars
        const myChart = new Chart(ctx, {
            type: 'line',
            data: this.chartData,
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    },
    data() {
        return {
            chartData: {
                labels: this.labels,
                fill: false,
                datasets: [
                    {
                        label: 'Produit',
                        data: [2, 8, 12],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                        ],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: 'Commande',
                        data: [12, 19, 3],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                        ],
                        borderWidth: 1,
                        fill: false,
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>

</style>
