<template>
  <router-link
      :to="to"
      class="block w-full flex items-center text-red-400 h-10 pl-4 bg-gray-200 hover:bg-gray-500 rounded-lg cursor-pointer">
    <slot name="icon"/>
    <span class="text-gray-700 hover:text-white">{{ label }}</span>
  </router-link>
</template>

<script>
export default {
  name: "SidebarButton",
  props: {
    label: {
      type: String
    },
    to: {
      type: String,
      default: '/'
    }
  }
}
</script>

<style scoped>

</style>
