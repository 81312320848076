<template>
  <div class="container mx-auto ">
    <div class="col-span-3 px-6 my-6">
      <h2 class="text-2xl font-bold text-gray-500 uppercase">Catégories des articles</h2>
    </div>
    <div class="grid grid-cols-3 bg-white overflow-hidden rounded-lg shadow-md bg-white p-6">

      <div>
        <CategoryEdit @created="addCategory" :categories="categories" />
      </div>
      <div class="col-span-2">
        <CategoryList :categories="categories" @remove="removeCategory($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryList from "@/pages/Article/Category/List";
import CategoryEdit from "@/pages/Article/Category/Edit";

export default {
  name: "CategoryCompose",
  components: { CategoryEdit, CategoryList },
  created() {
    this.getCategories();
  },
  data() {
    return {
      categories: [],
      error: null
    };
  },
  methods: {
    addCategory(category) {
      this.categories.push(category);
    },
    async getCategories() {
      const { data } = await this.$http.get("article_category");

      if (data) {
        this.categories = data;
      }
    },
    async removeCategory($event) {
      try {
        const { status } = await this.$http.delete("article_category/" + $event);

        if (status === 200) {
          const index = this.categories.findIndex(category => category._id === $event);
          this.categories.splice(index, 1);
        }
      } catch (e) {
       this.error = e.response.data.message
      }
    }
  }
};
</script>

<style scoped>

</style>
