<template>
  <EditForm @submit="submitBrand" :errors="errors" :is-update="isUpdate">
    <div class="container">
      <div class="grid grid-cols-1 gap-2">
        <div>
          <TextInput label-text="Nom" v-model="brand.name"/>
        </div>
        <div class="w-32 h-32" v-if="thumbnail">
          <img :src="thumbnail" alt="preview" class="h-full"/>
        </div>
        <div>
          <b-field class="file is-primary" :class="{'has-name': !!brand.logo}">
            <b-upload v-model="brand.logo" class="file-label" @input="loadThumbnail" expanded>
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Charger le logo</span>
            </span>
              <span class="file-name" v-if="brand.logo">
            </span>
            </b-upload>
          </b-field>
        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import TextInput from "@/components/TextInput";

export default {
  name: "AttributeEdit",
  components: {TextInput, EditForm},
  data() {
    return {
      errors: null,
      thumbnail: null,
      brand: {
        name: "",
        logo: null,
      },
      isUpdate: false
    };
  },
  methods: {
    loadThumbnail() {
      if (this.brand.logo) {
        const reader = new FileReader();
        const self = this;
        reader.onload = function () {
          // var output = document.getElementById('output_image');

          self.thumbnail = reader.result;
        }
        reader.readAsDataURL(this.brand.logo);
      }
    },
    reset() {
      this.thumbnail = null;
      this.brand = {
        title: "",
        logo: null
      };
      this.isUpdate = false;
    },
    // eslint-disable-next-line no-unused-vars
    editForm({logo, ...updateBrand}) {
      this.brand = {
        ...updateBrand
      };
      this.isUpdate = true;
    },
    async submitBrand() {
      this.errors = null;
      try {
        const formData = new FormData();

        formData.append("name", this.brand.name);
        formData.append("logo", this.brand.logo);

        if (!this.isUpdate) {
          const {data} = await this.$http.post("product_brand", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (data) {
            this.$emit("created", data);
            this.reset();
          }

        } else {
          const {data} = await this.$http.put("product_brand/" + this.brand._id, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (data) {
            this.$emit("updated", data);
            this.reset();
          }
        }
      } catch (e) {
        this.errors = {
          tilte: e.response.data.error,
          message: e.response.data.message
        };
      }
    }
  }
}
;
</script>

<style scoped>

</style>
