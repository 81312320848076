<template>
  <div class="container mx-auto ">
    <div class="col-span-3 px-6 my-6">
      <h2 class="text-2xl font-bold text-gray-500 uppercase">Marques</h2>
    </div>
    <div class="grid grid-cols-3 bg-white overflow-hidden rounded-lg shadow-md bg-white p-6">

      <div>
        <BrandEdit ref="brandEdit" @created="addBrand" />
      </div>
      <div class="col-span-2">
        <BrandList :brands="brands" @edit="updateBrand" @remove="removeBrand" />
      </div>
    </div>
  </div>
</template>

<script>
import BrandList from "@/pages/Product/Brand/List";
import BrandEdit from "@/pages/Product/Brand/Edit";

export default {
  name: "BrandCompose",
  components: { BrandEdit, BrandList },
  created() {
    this.getBrands();
  },
  data() {
    return {
      brands: []
    };
  },
  methods: {
    updateBrand(brand) {
      this.$refs.brandEdit.editForm(brand);
    },
    addBrand(brand) {
      this.brands.push(brand);
    },
    async getBrands() {
      const { data } = await this.$http.get("product_brand/with_logo");

      if (data) {
        this.brands = data;
      }
    },
    async removeBrand($event) {
      try {
        const { status } = await this.$http.delete("product_brand/" + $event);

        if (status === 200) {
          const index = this.brands.findIndex(brand => brand._id === $event);
          this.brands.splice(index, 1);
        }
      } catch (e) {
        this.error = e.response.data.message;
      }
    }
  }
};
</script>

<style scoped>

</style>
