<template>
  <ListView>
    <b-table
        :data="brands"
        :hoverable="true"
        :loading="false"
        :focusable="true"
        :mobile-cards="false"
        paginated
        :per-page="5"
    >
      <b-table-column label="Logo" width="80"
                      v-slot="props">
        <img :src="props.row.image_url" :alt="props.row.slug" style="width: 30px; height: 30px;">
      </b-table-column>
      <b-table-column :searchable="true" field="name" label="Nom" width="80"
                      v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column label="Actions" width="20" v-slot="props">
        <div class="grid grid-cols-2 gap-1">
          <b-button size="is-small" icon-left="lead-pencil" @click="$emit('edit', props.row)"/>
          <b-button size="is-small" icon-left="delete" @click="$emit('remove', props.row._id)"/>
        </div>
      </b-table-column>
    </b-table>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";

export default {
  name: "AttributeList",
  components: {ListView},
  props: {
    brands: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>

<style scoped>

</style>
