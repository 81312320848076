<template>
  <label class="block text-sm">
                <span  class="label">
                  {{ labelText }}
                </span>
    <select
      class="bg-white block h-10 border rounded w-full mt-1 text-sm border-red-300 focus:border-red-600 focus:outline-none focus:shadow-outline-red form-input"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      :value="value"
    >
      <option
        v-for="({value, name}, index) of options"
        v-bind:key="index"
        :value="value">
        {{ name }}
      </option>
    </select>
    <span class="font-weight-lighter text-red-400 text-xs mt-1" v-if="error">{{ error }}</span>
  </label>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    value: {
      type: String
    },
    labelText: {
      type: String
    },
    options: {
      type: Array,
      default: () => ([])
    },
    error: {
      type: String
    }
  }
};
</script>

<style scoped>

</style>
