<template>
  <Box>
    <header class="relative mb-20 w-full">
      <div class="absolute sm:top-1 sm:left-1">
        <h2>Fiche Client</h2>
      </div>
      <div class="absolute sm:top-1 sm:right-1">
        <LinkButton name="Éditer" :to="`/customer/${customer._id}/edit`"/>
      </div>
    </header>
    <div class="grid lg:grid-cols-2 sm:gap-2">
      <div class="grid gap-1 mb-6">
        <h3 class="mb-1">Information du client</h3>
        <div class=" grid lg:gap-1 sm:grid-cols-2 text-gray-800">
          <div>
            <span class="dashboard-label">Nom:</span>{{ customer.user.lastName }}
          </div>
          <div>
            <span class="dashboard-label">Prénom:</span>{{ customer.user.firstName }}
          </div>
          <div>
            <span class="dashboard-label">Email:</span>{{ customer.user.email }}
          </div>
          <div>
            <span class="dashboard-label">Téléphone:</span>{{ customer.user.phone }}
          </div>
          <div>
            <span class="dashboard-label">Inscrit le:</span>{{
              formatDate(customer.user.createdAt, 'DD-MM-YYYY')
            }}
          </div>
          <!--          <div>-->
          <!--            <span class="dashboard-label">Commandes effectués:</span> {{ customer_order }}-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <span class="dashboard-label">Commandes achevé:</span> {{ customer_completed_order }}-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <span class="dashboard-label">Produits achetés:</span> {{ customer.product_count }}-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <span class="dashboard-label">Montant dépensé:</span>-->
          <!--            {{ customer.spent_amount | currency }}-->
          <!--          </div>-->
        </div>
      </div>
      <!--      <div class="grid gap-1 mb-6">-->
      <!--        <h3 class="mb-1">Commandes effectuer</h3>-->
      <!--        <div class="h-48 overflow-y-auto">-->
      <!--          <table class="bg-gray-200 w-full whitespace-no-wrap">-->
      <!--            <thead class="text-gray-600">-->
      <!--            <tr>-->
      <!--              <th class="px-3 py-2">Numéro</th>-->
      <!--              <th class="px-3 py-2">Montant</th>-->
      <!--              <th class="px-3 py-2">Statut</th>-->
      <!--            </tr>-->
      <!--            </thead>-->
      <!--            <tbody class="bg-white divide-y">-->
      <!--            <tr-->
      <!--                v-for="order of customer.orders"-->
      <!--                :key="order.id"-->
      <!--                class="h-5 border-1 border-gray-600 text-gray-700 hover:bg-gray-100 cursor-pointer"-->
      <!--            >-->
      <!--              <td class="px-3 py-2">#{{ order.reference }}</td>-->
      <!--              <td class="px-3 py-2">{{ order.transaction.total }}</td>-->
      <!--              <td class="px-3 py-2">-->
      <!--                <OrderStatusBadge :status="order.status"/>-->
      <!--              </td>-->
      <!--            </tr>-->
      <!--            </tbody>-->
      <!--          </table>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="grid gap-1 mb-6">-->
      <!--        <h3 class="mb-1">Produits commandé</h3>-->
      <!--        <div>-->
      <!--          <table class="bg-gray-200 w-full whitespace-no-wrap">-->
      <!--            <thead class="text-gray-600">-->
      <!--            <tr>-->
      <!--              <th class="px-3 py-2">Nom</th>-->
      <!--              <th class="px-3 py-2">Nombre</th>-->
      <!--            </tr>-->
      <!--            </thead>-->
      <!--            <tbody class="bg-white divide-y">-->
      <!--            <tr-->
      <!--                v-for="product of orderedProducts"-->
      <!--                :key="product._id"-->
      <!--                class="h-5 border-1 border-gray-600 text-gray-700 hover:bg-gray-100 cursor-pointer">-->
      <!--              <td class="px-3 py-2">{{ product.name }}</td>-->
      <!--              <td class="px-3 py-2">{{ product.total }}</td>-->
      <!--            </tr>-->
      <!--            </tbody>-->
      <!--          </table>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="grid gap-6">-->
      <!--        <h3 class="mb-1">Interaction client</h3>-->
      <!--        <div>-->
      <!--          <table class="bg-gray-200 w-full whitespace-no-wrap">-->
      <!--            <thead class="text-gray-600">-->
      <!--            <tr>-->
      <!--              <th class="px-3 py-2">Via</th>-->
      <!--              <th class="px-3 py-2">Nombre</th>-->
      <!--            </tr>-->
      <!--            </thead>-->
      <!--            <tbody class="bg-white divide-y">-->
      <!--            <tr class="h-5 border-1 border-gray-600 text-gray-700 hover:bg-gray-100 cursor-pointer">-->
      <!--              <td class="px-3 py-2">Email</td>-->
      <!--              <td class="px-3 py-2">{{ customer_via_checked['email'] }}</td>-->
      <!--            </tr>-->
      <!--            <tr class="h-5 border-1 border-gray-200 text-gray-700 hover:bg-gray-100 cursor-pointer">-->
      <!--              <td class="px-3 py-2">Téléphone</td>-->
      <!--              <td class="px-3 py-2">{{ customer_via_checked['phone'] }}</td>-->
      <!--            </tr>-->
      <!--            </tbody>-->
      <!--          </table>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="grid gap-1 mb-6">
        <h3 class="mb-1">Adresses de livraison</h3>
        <div class=" grid lg:gap-1 sm:grid-cols-2 text-gray-800" v-for="(address, index) in customer.addresses"
             :key="index">
          <div>
            <span class="dashboard-label">Nom Complet:</span>{{ customer.addresses[index].fullName }}
          </div>
          <div>
            <span class="dashboard-label">Pays:</span>{{ customer.addresses[index].region }}
          </div>
          <div>
            <span class="dashboard-label">Région:</span>{{ customer.addresses[index].address }}
          </div>
          <div>
            <span class="dashboard-label">Commune:</span>{{ customer.addresses[index].phone }}
          </div>
        </div>
      </div>
    </div>
    <div class="grid gap-6">
      <h3 class="mb-1">Statistique</h3>
      <div class="grid lg:grid-cols-2 sm:gap-6">
        <!--        <customer-satisfaction-chart :satisfaction="satisfaction_count"/>-->
        <customer-amount-timeline-chart
        />
        <!--                        <customer-product-buying-chart/>-->
      </div>
    </div>
  </Box>

</template>

<script>
import Box from "@/components/Box";
// import OrderStatusBadge from "@/components/OrderStatusBadge";
// import CustomerProductBuyingChart from "@/components/charts/CustomerProductBuyingChart";
import CustomerAmountTimelineChart from "@/components/charts/CustomerAmountTimelineChart";
import dayjs from "dayjs";

export default {
  name: "CustomerView",
  components: {
    Box,
    // OrderStatusBadge,
    // CustomerProductBuyingChart,
    CustomerAmountTimelineChart,
  },
  created() {
    this.getCustomer()
  },
  data() {
    return {
      customer: {
        addresses: []
      },
      orderedProducts: [],
    }
  },
  methods: {
    formatDate(input, format = 'DD-MM-YYYY') {
      return dayjs(input, format);
    },
    async getCustomer() {
      const {data} = await this.$http.get('customer/' + this.$route.params.id)

      if (data) {
        this.customer = data;
      }
    }
  }
};
</script>

<style scoped>

</style>
