<template>
  <ListView title="Liste des utilisateurs">
    <template #toolbar>
      <b-button type="is-primary"
                tag="router-link"
                label="Nouvel Utilisateur"
                to="/dashboard/user/create"/>
    </template>
    <div class="container bg-white min-h-64 rounded-md shadow-sm p-6">
      <div>
        <div class="container mx-auto px-6">
          <div class="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6">
            <b-button
                :type="`${currentRole === 'ADMIN'?'is-primary': 'is-secondary is-light'}`"
                label="Administrateur"
                @click="changeRole('ADMIN')"
            />

            <b-button :type="`${currentRole === 'CUSTOMER'?'is-primary': 'is-secondary is-light'}`"
                      label="Clients"
                      @click="changeRole('CUSTOMER')"
            />
          </div>
        </div>

      </div>
      <div>
        <b-table
            :data="users"
            :hoverable="true"
            :loading="isLoading"
            :focusable="true"
            :mobile-cards="false"
            paginated
            :per-page="20"
        >
          <b-table-column :searchable="true" field="email" label="Email" width="200"
                          v-slot="props">
            {{ props.row.email }}
          </b-table-column>
          <b-table-column :searchable="true" field="fullName" label="Nom" width="200"
                          v-slot="props">
            {{ props.row.fullName }}
          </b-table-column>
          <b-table-column sortable field="status" label="Etat" width="200"
                          v-slot="props">
            <b-field>
              <b-switch :value="props.row.status"
                        @input="changeStatus(props.row._id, $event)"
                        true-value="ACTIVATED"
                        false-value="SUSPENDED"
                        size="is-small"/>

            </b-field>
          </b-table-column>
          <b-table-column sortable field="createdAt" label="Créé le" width="200"
                          v-slot="props">
            {{ (new Date(props.row.createdAt)).toLocaleDateString() }}
          </b-table-column>
          <b-table-column sortable field="firstName" label="Actions" width="200"
                          v-slot="props">
            <b-button tag="router-link" :to="`/dashboard/user/view/${props.row._id}`" icon-left="lead-pencil"/>
          </b-table-column>
        </b-table>
      </div>

    </div>
  </ListView>
</template>

<script>
import ListView from '@/components/ListView';

export default {
  name: 'UserList',
  components: {ListView},
  created() {
    this.getUsers();
  },
  data() {
    return {
      users: [],
      isLoading: false,
      currentRole: 'ADMIN'
    };
  },
  methods: {
    async getUsers() {
      this.isLoading = true;

      try {
        const {data, status} = await this.$http.get('/user', {
          params: {
            role: this.currentRole
          }
        });

        if (status === 200) {
          this.users = data
        }
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false;
    },
    changeRole(role) {
      this.currentRole = role;
      this.getUsers()
    },
    async changeStatus(userId, $event) {
      try {
        await this.$http.patch(`user/${userId}/status`, {
          status: $event
        });
      } catch (e) {
        console.log(e)
      }

    },
  }
};
</script>

<style scoped>

</style>
