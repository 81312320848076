<template>
    <StatusBadge :color="productStatusColor(status)" :label="productStatusText(status)"/>
</template>

<script>
import ProductExtend from "@/mixins/ProductExtend";
import StatusBadge from "@/components/StatusBadge";

export default {
    name: "ProductStatusBadge",
    components: {StatusBadge},
    mixins: [ProductExtend],
    props: {
        status: {
            type: String
        }
    }
}
</script>

<style scoped>

</style>
