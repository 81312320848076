<template>
  <main class="grid-container">
    <div class="flex-container align-center-middle" style="min-height: 500px;">
      <FadeLoader color="#DB3F52"/>
    </div>
  </main>

</template>

<script>
import FadeLoader from "vue-spinner/src/FadeLoader";
import AuthHandler from "@/mixins/AuthHandler";

export default {
  name: "Logout",
  components: {
    FadeLoader
  },
  mixins: [AuthHandler],
  async created() {
    // const response = await this.apiGet('/logout');
    //
    // if (response.data) {
    this.setLogout();
    await this.$router.push('/')
    // }

  }
}
</script>

<style scoped>

</style>
