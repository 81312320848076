<template>
  <EditForm title="Ajouter un nouvel utilisateur" @submit="submitUser" :errors="errors" :is-update="isUpdate">
    <div class="container px-10">
      <div class="grid lg:grid-cols-2 gap-10">
        <div class="col-span-2">
          <Multiselect
              v-model="user.roles"
              :preselect-first="true"
              label="name"
              track-by="value"
              :options="roles"
              :multiple="true"
          />
        </div>
        <div>
          <b-field label="Prénom">
            <b-input v-model="user.firstName"/>
          </b-field>
        </div>
        <div>
          <b-field label="Nom">
            <b-input v-model="user.lastName"/>
          </b-field>
        </div>
        <div>
          <b-field label="Email">
            <b-input v-model="user.email" type="email"/>
          </b-field>
        </div>
        <div>
          <b-field label="Téléphone">
            <b-input v-model="user.phone" type="tel"/>
          </b-field>
        </div>
        <div>
          <b-field label="Mot de passe">
            <b-input v-model="user.password" type="password"/>
          </b-field>
        </div>
        <!--        <div>-->
        <!--          <b-field label="Confirmer le mot de passe">-->
        <!--            <b-input type="password" v-model="user.password"/>-->
        <!--          </b-field>-->
        <!--        </div>-->
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import Multiselect from "vue-multiselect";

export default {
  name: "UserCreate",
  components: {Multiselect, EditForm},
  props: {
    isUpdate: {
      type: Boolean,
      default: () => false
    }
  },
  created() {
    if (this.isUpdate) {
      this.editForm()
    }
  },
  data() {
    return {
      errors: null,
      roles: [
        {name: "Administrateur", value: "ADMIN"},
        {name: "Gestionnaire", value: "MANAGER"},
        {name: "Contributeur", value: "CONTRIB"},
        {name: "Client", value: "CUSTOMER"}
      ],
      user: {
        roles: [{name: "Administrateur", value: "ADMIN"}],
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: ""
      }
    };
  },
  methods: {
    async editForm() {
      try {
        const {data, status} = await this.$http.get(`user/${this.$route.params.id}`);

        // eslint-disable-next-line no-unused-vars
        const {password, ...result} = data;

        if (status === 200) {
          this.user = {
            ...result,
            roles: result.roles.map(role => {
              switch (role) {
                case "ADMIN":
                  return {name: "Administrateur", value: "ADMIN"};
                case "MANAGER":
                  return {name: "Gestionnaire", value: "MANAGER"};
                case "CONTRIB":
                  return {name: "Contributeur", value: "CONTRIB"};
                case "CUSTOMER":
                  return {name: "Client", value: "CUSTOMER"};
              }
            })
          };
        }
      } catch (e) {
        console.log(e)
      }

    },
    async submitUser() {
      this.errors = null;
      try {
        if (!this.isUpdate) {
          const {status} = await this.$http.post("user", {
            ...this.user,
            roles: this.user.roles.map(role => role.value)
          });

          if (status === 201) {
            await this.$router.push("/dashboard/user/list");
          }
        } else {
          const {status} = await this.$http.put("user", {
            ...this.user,
            roles: this.user.roles.map(role => role.value)
          });

          if (status === 200) {
            await this.$router.push("/dashboard/user/list");
          }
        }

      } catch (e) {
        console.log('error', e.response);
        this.errors = e.response.data
      }
    }
  }
};
</script>

<style scoped>

</style>
