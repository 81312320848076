<template>
  <Box>
    <div class="mx-auto container">
      <div class="grid grid-cols-1">
        <b-field label="Bloquer les inscriptions">
          <b-switch :value="metadata('CUSTOMER_BLOCK_SIGNUP')" size="is-medium"
                    @input="$emit('change','CUSTOMER_BLOCK_SIGNUP', $event)"/>
        </b-field>
        <b-field label="Bloquer les connections">
          <b-switch :value="metadata('CUSTOMER_BLOCK_SIGNIN')" size="is-medium"
                    @input="$emit('change', 'CUSTOMER_BLOCK_SIGNIN', $event)"/>
        </b-field>
      </div>
    </div>

  </Box>
</template>

<script>
import Box from "@/components/Box";

export default {
  name: "UserSetting",
  components: {Box},
  props: {
    settings: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    metadata() {
      return function (key) {
        const settings = this.settings.filter(setting => setting.key === key)

        if (settings.length) {
          return settings[0].metadata;
        }

        return null
      }
    }
  }

}
</script>

<style scoped>

</style>
