<template>
  <div class="main-content media-manager">
    <div class="grid-container fluid">
      <b-tabs v-model="activeTab">
        <b-tab-item label="Charger un fichier">
          <div class="mb-4" v-if="errors">
            <b-message :title="errors.title" type="is-danger" has-icon aria-close-label="Close message"
                       size="is-small">
              <template v-if="Array.isArray(errors.message)">
                <div class="block" v-for="(error, index) in errors.message" :key="index">{{ error }}</div>
              </template>
              <div class="block" v-else>{{ errors.message }}</div>
            </b-message>
          </div>

          <b-field>
            <b-upload v-model="file"
                      expanded
                      drag-drop
                      @input="uploadFile"
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                        icon="upload"
                        size="is-large">
                    </b-icon>
                  </p>
                  <p>GLissez ou cliquez pour charger un fichier</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags">
            <span v-for="(file, index) in dropFiles"
                  :key="index"
                  class="tag is-primary">
                {{ file.name }}
                <button class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)">
                </button>
            </span>
          </div>
        </b-tab-item>
        <b-tab-item label="Médiathèque">
          <div class="grid grid-cols-12">
            <div class="sm:col-span-12 md:col-span-8 cell">
              <div class="mb-2">
                <b-input @input="findInAttachments" icon-right="magnify"/>
              </div>
              <div class="media-container">
                <transition-group name="slide" class="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
                  <div
                      v-for="attachment in displayedAttachments"
                      :key="attachment._id"
                  >
                    <FileCard
                        :show-select="isMultiple"
                        :attachment="attachment"
                        @select="onSelect(attachment)"
                        @unselect="onUnSelect(attachment)"
                    />
                  </div>
                </transition-group>
              </div>
              <div class="footer"></div>
            </div>
            <div class="col-span-4 px-2">
              <div class="grid grid-cols-1" style="">
                <div style="height: 80%;">
                  <transition name="fade">
                    <div class="right-sidebar-container" v-if="Object.keys(selected).length">
                      <div class="grid grid-cols-1 ">
                        <!-- <div class="cell small-12">
                          {{selectedValues.length}} Fichier(s)
                       </div> -->
                        <div class="media-thumbnail-big">
                          <img :src="selected.image_url" :alt="selected.filename.split('/')"/>
                        </div>
                        <div class="">
                          <ul class="media-meta-container">
                            <li class="media-meta media-meta-name">{{ selected.filename.split('/')[1] }}</li>
                            <li class="media-meta">{{ (new Date(selected.createdAt)).toLocaleString() }}</li>
                            <li class="media-meta">{{ formatBytes(selected.size) }}</li>
                            <li class="media-meta">{{ "Type: " + typeName(selected.contentType) }}</li>
                            <li class="my-6">
                              <transition name="slide">
                                <div class="center">
                                  <b-button
                                      expanded
                                      v-if="Object.keys(selected).length || selectedValues.length"
                                      @click="insert"
                                      type="is-primary"
                                      icon-right="image-plus"
                                  >Insérer
                                  </b-button>
                                </div>
                              </transition>
                            </li>
                            <li>
                              <div class="delete-btn">
                                <b-button @click="deleteFile(selected._id)" icon-left="delete"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  </transition>
                </div>
                <div class="">
                  <div class="grid grid-cols-1">
                    <div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>

    </div>
  </div>
</template>

<script>
import FileCard from "@/components/file-manager/FileCard";
import FileHandler from "@/components/file-manager/FileHandler";
import dayjs from "dayjs";


export default {
  name: "FileManager",
  components: {
    FileCard
  },
  mixins: [FileHandler],
  props: {
    isMultiple: {
      type: Boolean,
      default: false
    },
    destination: {
      type: String,
      default: "thumbnails"
    }
  },
  mounted() {
    this.getAttachments();
  },
  data() {
    return {
      errors: null,
      activeTab: 0,
      attachments: [],
      selected: {},
      selectedValues: [],
      file: null,
      dropFiles: [],
      displayedAttachments: []
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    insert() {
      if (this.isMultiple) {
        this.$parent.$emit("closed", {
          selected: this.selectedValues.map(selected => selected._id),
          link: this.selectedValues.map(
              selected => selected.image_url
          )
        });
      } else {
        this.$parent.$emit("closed", {
          selected: this.selected._id,
          link: this.selected.image_url
        });
      }
      this.resetAttachements();
    },
    onUnSelect($event) {
      if (this.isMultiple) {
        const found = this.selectedValues.find(
            attachment => attachment === $event
        );

        this.selectedValues.splice(found, 1);
      }
    },
    onSelect(attachment) {
      console.log(attachment);
      if (!this.isMultiple) {
        this.selected = attachment;
      } else {
        const index = this.selectedValues.findIndex(
            element => element._id === attachment._id
        );
        if (index === -1) {
          this.selectedValues.push(attachment);
        } else {
          this.selectedValues.splice(index, 1);
        }
        this.selected = attachment;
      }
    },
    async getAttachments() {
      try {
        const { data, status } = await this.$http.get("/attachment", {
          headers: {
            Accept: "application/json"
          }
        });

        if (status === 200) {
          this.attachments = data;
          this.resetAttachements();
        }
      } catch (e) {
        this.$buefy.notification.open({
          message: "Erreur chargement des images."
        });
      }

    },
    findInAttachments(input) {
      console.log(input);
      if (input === "" || input.length < 2) {
        this.resetAttachements();
      } else if (input.length > 2) {

        this.displayedAttachments = this.attachments.filter(attachment => attachment.filename.toLowerCase().includes(input.toLowerCase()));
        console.log(this.displayedAttachments);
      }
    },
    resetAttachements() {
      this.displayedAttachments = this.attachments;
    },
    async deleteFile(id) {
      const {status} = await this.$http.delete("/attachment/" + id);

      if (status) {
        const index = this.attachments.findIndex(
            attachment => attachment._id === id
        );
        this.attachments.splice(index, 1);
        this.selected = {};
      }
      this.resetAttachements();
    },
    formatDate(date) {
      return dayjs(date, "DD-MM-YYYY HH:mm:ss").format("llll");
    },
    async uploadFile() {
      this.errors = null;
      try {
        let formData = new FormData();
        formData.append("file", this.file);
        const {data} = await this.$http.post("/attachment/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        if (data) {
          this.activeTab = 1;

          this.attachments.unshift(data);

          // if (this.isMultiple) {
          //   this.selectedValues.push(data);
          // } else {
          //   this.selected = data;
          // }
        }
      } catch (e) {
        this.errors = e.response.data
      }
      this.resetAttachements();
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/sass/partials/_global.scss";

.media-manager {
  padding: 30px;

  .media-container {
    height: calc(75vh - 0px);
    overflow-y: scroll;
    padding-left: 14px;
    padding-right: 14px;
    border-bottom: 1px solid $secondary-color;

    .media-thumbnail {
      padding: 5px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }

      @include breakpoint(laptop) {
        height: 193px;
      }
      height: 123px;
      background-color: $grey-color;
    }
  }

  .right-sidebar-container {
    background-color: $the-grey;
    padding: 15px;

    .media-thumbnail-big {
      max-height: 200px;
      overflow: hidden;
      margin-bottom: 12px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: top;
      }
    }

    .delete-link {
      margin-top: 15px;

      font-weight: 400;
      font-size: calculateRem(14px);
      color: $secondary-color;
    }


    .media-meta-container {
      list-style-type: none;
      padding: 5px;
      margin-top: 0;

      .media-meta {
        padding: 2px 0;
        word-break: break-all;
        font-size: 12px;


      }

      .media-meta-name {
        color: $the-black;
        font-weight: 500;
      }
    }
  }

  .footer {
    display: none;
    box-shadow: rgba(63, 58, 87, 0.16) 0px -28px 140px;
    height: 80px;
  }
}


.load-btn {
  display: block;
}

.insert-btn {
  background-color: $primary-color;
  border: 2px solid $primary-color;
  display: block;
}

.delete-btn {
  margin-top: 3px;
  display: block;
  margin-bottom: 3px;
}
</style>
