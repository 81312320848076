<template>
  <ListView >

    <b-table
        :data="tags"
        :hoverable="true"
        :loading="false"
        :focusable="true"
        :mobile-cards="false"
        paginated
        :per-page="5"
    >
      <b-table-column :searchable="true" field="name" label="Nom" width="80"
                      v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column label="Actions" width="20" v-slot="props">
        <b-button icon-left="delete" @click="$emit('remove', props.row._id)"/>
      </b-table-column>
    </b-table>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";
// import Draggable from "vuedraggable";

export default {
  name: "AttributeList",
  components: {ListView,
    // Draggable
  },
  props: {
    tags: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>

<style scoped>

</style>
