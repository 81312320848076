<template>
  <EditForm title="" @submit="submitTagGroup">
    <div class="container">
      <div class="grid grid-cols-1 gap-2">
        <div>
          <TextInput label-text="Nom" v-model="tagGroup.name" />
        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import TextInput from "@/components/TextInput";

export default {
  name: "TagGroupEdit",
  components: { TextInput, EditForm },
  data() {
    return {
      tagGroup: {
        name: "",
      }
    };
  },
  methods: {
    reset() {
      this.tagGroup = {
        title: "",
      };
    },
    async submitTagGroup() {
      const { data } = await this.$http.post("product_tag_group", this.tagGroup);

      if (data) {
        this.$emit("created", this.tagGroup);
        this.reset()
      }
    }
  }
};
</script>

<style scoped>

</style>
