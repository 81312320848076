<template>
  <ListView>
    <b-table
        :data="categories"
        :hoverable="true"
        :loading="false"
        :focusable="true"
        :mobile-cards="false"
        paginated
        :per-page="5"
    >
      <b-table-column label="" width="80"
                      v-slot="props">
        <div v-if="props.row.bannerImage">
          <img class="w-20" :src="props.row.bannerImage" alt="catimg">
        </div>
      </b-table-column>
      <b-table-column :searchable="true" field="name" label="Nom" width="80"
                      v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="parent" label="Parent" width="80"
                      v-slot="props">
        {{ props.row.parent ? props.row.parent.name : "" }}
      </b-table-column>
      <b-table-column field="level" label="Niveau" width="80"
                      v-slot="props">
        {{ props.row.level }}
      </b-table-column>
      <b-table-column field="order" label="Ordre" width="80"
                      v-slot="props">
        {{ props.row.order }}
      </b-table-column>
      <b-table-column label="Actions" width="80" v-slot="props">
        <b-button v-if="props.row.level !== 3" icon-left="eye" size="is-small"
                  @click="$emit('view', props.row)"/>
        <b-button icon-left="lead-pencil" size="is-small"
                  @click="$emit('edit', props.row)"/>
        <b-button icon-left="delete" size="is-small"
                  @click="$emit('remove', props.row._id)"/>
      </b-table-column>
    </b-table>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";
// import Draggable from "vuedraggable";

export default {
  name: "CategoryList",
  components: {
    ListView,
    // Draggable
  },
  props: {
    categories: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>

<style scoped>

</style>
