<template>
  <div class="media-thumbnail"
       :media-extension="this.capitalize(attachment.contentType)"
       @click="onSelectOne">
    <img v-if="attachment.extension !== 'image/jpg' || attachment.extension !== 'image/png'
    || attachment.extension !== 'image/jpeg' || attachment.extension !== 'image/webp'"
         :src="`${attachment.image_url}`"
         :alt="attachment.type">
    <img v-else src="@/assets/images/document.jpg" :alt="attachment.type">
    <label>
      <input ref="checkbox" v-if="showSelect" class="thumbnail-checkbox" type="checkbox" @change="onSelected">
    </label>
<!--    :srcset="`-->
<!--    /storage/${removeExtension(attachment.filename)}-100.webp 100w,-->
<!--    /storage/${removeExtension(attachment.filename)}-180.webp 180w,-->
<!--    /storage/${removeExtension(attachment.filename)}-300.webp 300w,-->
<!--    /storage/${removeExtension(attachment.filename)}-600.webp 600w,-->
<!--    `"-->
  </div>

</template>

<script>
import FileHandler from "@/components/file-manager/FileHandler";
import _ from "lodash";

export default {
  name: "FileCard",
  mixins: [FileHandler],
  props: {
    showSelect: {
      type: Boolean,
      default: false
    },
    attachment: Object
  },
  methods: {
    removeExtension(input){
      return input.replace(/\.[^/.]+$/, "")
    },
    onSelected($event) {
      if (this.showSelect) {
        if ($event.target.checked) {
          this.$emit("select");
        } else {
          this.$emit("unselect");
        }
      }
    },
    onSelectOne() {
      if (this.showSelect) {
        this.$refs.checkbox.checked = !this.$refs.checkbox.checked;
      }
      this.$emit("select");

    },
    capitalize(str) {
      return _.toUpper(str);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/sass/partials/_global.scss";

.media-thumbnail {
  padding: 5px;
  position: relative;
  cursor: pointer;

  .thumbnail-checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  /* &::before {
       content: attr(media-type);
       background-color: $primary-color;
       height: auto;
       width: auto;
       position: absolute;
       top: 5px;
       left: 5px;
       opacity: 0.6;
       font-family: "Roboto", sans-serif;
       font-weight: 200;
       font-size: calculateRem(16px);
       z-index: 2;
       padding: 5px 10px;
       color: white;
   }*/

  &::after {
    content: attr(media-extension);
    background-color: $secondary-color;
    height: auto;
    width: auto;
    position: absolute;
    bottom: 5px;
    right: 5px;
    opacity: 0.6;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: calculateRem(10px);
    z-index: 2;
    padding: 5px 10px;
    color: white;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  @include breakpoint(laptop) {
    height: 193px;
  }
  height: 123px;
  background-color: $grey-color;
}
</style>
