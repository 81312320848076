<template>
  <Box title="Réglages">
    <b-tabs v-model="activeTab" type="is-boxed" expanded>
      <b-tab-item label="Utilisateurs">
        <UserSetting @change="updateSetting" :settings="settings"/>
      </b-tab-item>
      <b-tab-item label="Commandes">
        <OrderSetting @change="updateSetting" :settings="settings"/>
      </b-tab-item>
      <b-tab-item label="Produits">
        <ProductSetting/>
      </b-tab-item>
      <b-tab-item label="Livraison">
        <DeliverySetting @change="updateSetting" :settings="settings"/>
      </b-tab-item>
    </b-tabs>
  </Box>
</template>

<script>
import Box from "@/components/Box";
import DeliverySetting from "@/pages/Setting/DeliverySetting";
import OrderSetting from "@/pages/Setting/OrderSetting";
import UserSetting from "@/pages/Setting/UserSetting";
import ProductSetting from "@/pages/Setting/ProductSetting";

export default {
  name: "index",
  components: {ProductSetting, UserSetting, OrderSetting, DeliverySetting, Box},
  data() {
    return {
      activeTab: 0,
      settings: []
    }
  },
  created() {
    this.getSettings();
  },
  methods: {
    async updateSetting(key, value) {
      // console.log(key, body)
      await this.$http.patch(`setting/${key}`, {metadata: value})
    },
    async getSettings() {
      const {data, status} = await this.$http.get('setting');

      if (status === 200) {
        this.settings = data
      }
    }
  }
}
</script>

<style scoped>

</style>