<template>
<span
    :class="`px-2 py-1 font-semibold leading-tight text-${color}-700 bg-${color}-100 rounded-full`"
>{{ label }}</span>
</template>

<script>
export default {
    name: "StatusBadge",
    props: {
        color: {
            type: String,
            required: true
        },
        label: {
            type: String
        }
    }
}
</script>

<style scoped>

</style>
