<template>
  <Box>
    <div class="container mx-auto">
      <div>
        <header class="relative mb-20 w-full">
          <div class="absolute top-1 left-1" style="top: 1rem; left: 1rem;">
            <h2 class="text-3xl text-gray-600 font-bold">Fiche utilisateur</h2>
          </div>
          <div class="absolute top-1 right-1" style="top: 1rem; right: 1rem;">
            <b-button icon-left="lead-pencil" label="Éditer" tag="router-link" :to="`/dashboard/user/edit/${user._id}`"/>
          </div>
        </header>
      </div>

      <section class="mt-16 grid lg:grid-cols-2 gap-2 mb-6">
        <div>
          <span class="dashboard-label">Nom:</span>{{ user.firstName }} {{ user.lastName }}

        </div>
        <div>
          <span class="dashboard-label">Email:</span>{{ user.email }}
        </div>
        <div>
          <span class="dashboard-label">Role:</span>{{ processedRoles }}
        </div>
      </section>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/Box";

export default {
  name: 'UserView',
  components: {Box},
  created() {
    this.getUser()
  },
  data() {
    return {
      user: {}
    }
  },
  computed: {
    processedRoles() {
      return this.user.roles.map(role => this.roleText(role)).join(', ')
    }
  },
  methods: {
    roleText(input) {
      switch (input) {
        case "DELIVERY":
          return "Livreur";
        case "CUSTOMER":
          return "Client";
        case "CONTRIB":
          return "Contributeur";
        case "MANAGER":
          return "Manager";
      }
    },
    async getUser() {
      try {
        const {data, status} = await this.$http.get(`user/${this.$route.params.id}`);

        if (status === 200) {
          this.user = data;
        }
      } catch (e) {
        console.log(e)
      }

    }
  }
};
</script>

<style scoped>

</style>
