<template>
  <Box>
    <div class="mx-auto container">
      <div class="grid grid-cols-1">
        <b-button type="is-primary" label="Reconstruire l'indexe" :loading="isRebuildingIndex" @click="rebuildIndex"/>
      </div>
    </div>
  </Box>
</template>

<script>
export default {
  name: "ProductSetting",
  data() {
    return {
      isRebuildingIndex: false
    }
  },
  methods: {
    async rebuildIndex() {
      this.isRebuildingIndex = true
      try {
        await this.$http.get('product/delete_index')
        await this.$http.get('product/build_index')
        this.$buefy.notification.open(
            {
              message: "Index reconstruit",
              type: 'is-success'
            }
        )
      } catch (e) {
        this.$buefy.notification.open(
            {
              message: "Échec de la reconstruction de l'index",
              type: 'is-danger'
            }
        )
      }
      this.isRebuildingIndex = false
    }
  }
}
</script>

<style scoped>

</style>