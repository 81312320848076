<template>
  <div class="container mx-auto ">
    <div class="col-span-3 px-6 my-6">
      <h2 class="text-2xl font-bold text-gray-500 uppercase">Attributs des produits</h2>
    </div>
    <div class="grid xl:grid-cols-3 bg-white overflow-hidden rounded-lg shadow-md bg-white p-6">
      <div>
        <AttributeEdit @created="addAttribute" @updated="updatedAttribute" ref="attributeEdit"/>
      </div>
      <div class="col-span-2">
        <AttributeList :attributes="attributes" @edit="editAttribute" @remove="removeAttribute"/>
      </div>
    </div>
  </div>
</template>

<script>
import AttributeList from "@/pages/Product/Attribute/List";
import AttributeEdit from "@/pages/Product/Attribute/Edit";

export default {
  name: "AttributeCompose",
  components: {AttributeEdit, AttributeList},
  created() {
    this.getAttributes();
  },
  data() {
    return {
      attributes: []
    };
  },
  methods: {
    editAttribute(attribute) {
      this.$refs.attributeEdit.editForm(attribute);

    },
    async removeAttribute(attributeId) {
      const {status} = await this.$http.delete('attribute/' + attributeId)

      if (status === 200) {
        const index = this.attributes.findIndex(attribute => attribute._id === attributeId);
        this.attributes.splice(index, 1);
      }
    },
    addAttribute(attribute) {
      this.attributes.push(attribute);
    },
    async getAttributes() {
      const {data} = await this.$http.get("attribute");

      if (data) {
        this.attributes = data;
      }
    },
    updatedAttribute(attribute) {

      const index = this.attributes.findIndex(attributeItem => attributeItem._id === attribute._id);
      this.attributes.splice(index, 1);

      this.addAttribute(attribute);
    }
  }
};
</script>

<style scoped>

</style>
