<template>
  <ListView title="Liste des bannières">
    <template #toolbar>
      <div class="container mx-auto">
        <div class="grid grid-cols-4">
          <b-button
              expanded
              class="is-primary"
              tag="router-link"
              to="/dashboard/banner/create"
          >Nouvelle Bannière
          </b-button>
        </div>
      </div>
    </template>
    <div class="container bg-white min-h-64 rounded-md shadow-sm p-6">
      <b-table
          :data="banners"
      >

        <b-table-column field="name" label="Nom" width="40" numeric v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="zone" label="Emplacement" v-slot="props">
          {{ props.row.zone }}
        </b-table-column>

        <b-table-column field="elements" label="Nombre d'elements" v-slot="props">
          {{ props.row.elements.length }}
        </b-table-column>

        <b-table-column field="zone" label="Actions" v-slot="props">
          <div class="buttons">
            <b-button tag="router-link" :to="`/dashboard/banner/edit/${props.row._id}`" icon-left="lead-pencil"/>
            <b-button @click="removeBanner(props.row._id)" icon-right="delete" type="is-primary"/>
          </div>

        </b-table-column>

        <template #empty>
          <div class="has-text-centered">Aucun menu</div>
        </template>

      </b-table>

    </div>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";

export default {
  name: "index",
  components: {ListView},
  created() {
    this.getBanners()
  },
  data() {
    return {
      banners: []
    }
  },
  methods: {
    async getBanners() {
      const {data} = await this.$http.get('/banner');

      if (data) {
        this.banners = data;
      }
    },
    async removeBanner(bannerId) {
      const {status} = await this.$http.delete(`/banner/${bannerId}`)

      if (status === 200) {
        const index = this.banners.findIndex(banner => banner._id === bannerId);
        this.banners.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
