<template>
  <StatusBadge :color="statusColor(status)" :label="statusText(status)"/>
</template>

<script>
import StatusBadge from "@/components/StatusBadge";
import OrderExtend from "@/mixins/OrderExtend";

export default {
  name: "OrderStatusBadge",
  components: {StatusBadge},
  mixins: [OrderExtend],
  props: {
    status: {
      type: String
    }
  }

};
</script>

<style scoped>

</style>
