<template>
  <EditForm title="Rédiger un article" @submit="submitArticle" :errors="errors" :is-update="isUpdate">
    <div class="container mx-auto">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-4">
          <b-field label="Titre">
            <b-input v-model="article.title" placeholder="Titre"/>
          </b-field>
          <b-field label="Description">
            <b-input v-model="article.description" type="textarea"/>
          </b-field>
          <b-field label="Description SEO">
            <b-input v-model="article.seoDescription" :row="2" placeholder="Description SEO..."/>
          </b-field>
        </div>
        <div class="col-span-2">
          <b-field label="Catégorie">
            <b-select placeholder="Choisir une catégorie" expanded v-model="article.category">
              <option
                  v-for="option in categories"
                  :value="option._id"
                  :key="option._id">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="État">
            <b-select placeholder="Définir un état" expanded v-model="article.status">
              <option
                  v-for="option in statusList"
                  :value="option.value"
                  :key="option.value">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <div>
            <div class="w-64 h-64" v-if="thumbnail">
              <b-image :src="thumbnail" alt="preview" class="cover h-full"/>
            </div>
          </div>
          <div class="flex flex-row justify-center">
            <b-field class="file is-primary" :class="{'has-name': !!article.image}" expanded>
              <b-upload v-model="article.image" class="file-label" @input="loadThumbnail" expanded>
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Charger une image</span>
            </span>
                <span class="file-name" v-if="article.image">
                {{ article.image.name }}
            </span>
              </b-upload>
            </b-field>
          </div>
        </div>
        <div class="col-span-6">
          <quill-editor
              ref="myQuillEditor"
              :content="article.content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onEditorChange($event)"
          />
        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import Quill from "quill";
import ImageResize from "quill-image-resize";
import {ImageDrop} from "quill-image-drop-module";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {quillEditor} from "vue-quill-editor";
import {container, QuillWatch} from "quill-image-extend-module";
import {mapGetters} from "vuex";
import ImageUploader from "quill-image-uploader";


Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/ImageDrop", ImageDrop);
Quill.register("modules/imageUploader", ImageUploader);

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  ["link", "image"],
  [{"header": 1}, {"header": 2}],
  [{"list": "ordered"}, {"list": "bullet"}],
  [{"script": "sub"}, {"script": "super"}],
  [{"indent": "-1"}, {"indent": "+1"}],
  [{"direction": "rtl"}],

  [{"size": ["small", false, "large", "huge"]}],
  [{"header": [1, 2, 3, 4, 5, 6, false]}],

  [{"color": []}, {"background": []}],
  [{"font": []}],
  [{"align": []}],

  ["clean"]
];

export default {
  name: "Create",
  components: {EditForm, quillEditor},
  props: {
    isUpdate: {
      type: Boolean,
      default: () => false
    }
  },
  created() {
    this.getCategories();

    if (this.isUpdate) {
      this.editForm();
    }
  },
  data() {
    const self = this;
    return {
      thumbnail: null,
      errors: false,
      editorOption: {
        modules: {
          toolbar: {
            ...toolbarOptions,
            container: container,
            handlers: {
              "image": function () {
                QuillWatch.emit(this.quill.id);
              }
            }
          },
          ImageDrop: true,
          ImageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"]
          },
          imageUploader: {
            // eslint-disable-next-line no-unused-vars
            upload: async (file) => {
              try {
                const formData = new FormData();
                formData.append('file', file);

                const {data} = await self.$http.post('attachment/editor/upload', formData, {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                });
                return data.data.url;
              } catch (e) {
                console.log(e);
              }
            },
          }
        }
      },
      statusList: [
        {name: "Publier", value: "PUBLISHED"},
        {name: "En Attente", value: "PENDING"},
        {name: "Désactiver", value: "DEACTIVATE"}
      ],
      categories: [],
      article: {
        title: "",
        description: "",
        content: "",
        seoDescription: "",
        category: null,
        image: null,
        status: 'PUBLISHED'
      }
    };
  },
  computed: {
    ...mapGetters([
      "accessToken"
    ])
  },
  methods: {
    async editForm() {
      const {data} = await this.$http.get("/article/by_id/" + this.$route.params.id);
      if (data) {
        // eslint-disable-next-line no-unused-vars
        const {image_url, image, ...articleData} = data;
        this.thumbnail = image_url
        this.article = articleData;
        // this.thumbnails = data.images_url;
      }
    },
    loadThumbnail() {
      if (this.article.image) {
        const reader = new FileReader();
        const self = this;
        reader.onload = function () {
          self.thumbnail = reader.result;
        }
        reader.readAsDataURL(this.article.image);
      }
    },
    async submitArticle() {
      this.errors = false;
      try {
        const formData = new FormData();

        formData.append("title", this.article.title);
        formData.append("description", this.article.description);
        formData.append("content", this.article.content);
        formData.append("seoDescription", this.article.seoDescription);
        formData.append("category", this.article.category);
        formData.append("status", this.article.status);
        if (this.article.image) {
          formData.append("image", this.article.image);
        }

        if (!this.isUpdate) {
          const {status} = await this.$http.post("article", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (status === 201) {
            await this.$router.push("/dashboard/article/list");
          }
        } else {
          const {status} = await this.$http.put(`article/${this.$route.params.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (status === 200) {
            await this.$router.push("/dashboard/article/list");
          }
        }


      } catch (e) {
        console.error(e.response.data);
        this.errors = {
          tilte: e.response.data.error,
          message: e.response.data.message
        };
      }

    },
    async getCategories() {
      const {data} = await this.$http.get("article_category");

      if (data) {
        this.categories = data;
      }
    },
    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
      // console.log("editor blur!", quill);
    },
    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
      // console.log("editor focus!", quill);
    },
    // eslint-disable-next-line no-unused-vars
    onEditorReady(quill) {
      // console.log("editor ready!", quill);
    },
    // eslint-disable-next-line no-unused-vars
    onEditorChange({quill, html, text}) {
      // console.log("editor change!", quill, html, text);
      this.article.content = html;
    }
  }
};
</script>

<style scoped>

</style>
