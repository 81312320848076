<template>
  <ListView title="Transactions">
    <div
        slot="toolbar"
        class="h-full overflow-y-auto"
    >
      <div class="px-6 container mx-auto">
        <!-- New Table -->
        <div class=" mb-8 ">
          <div class="col-span-4">
            <h4 class="uppercase text-gray-500 font-medium">filtres:</h4>
          </div>
          <div class="grid gap-6 md:grid-cols-4 my-10">
            <link-button
                :to="`?filter=today&status=${$route.query.status}`"
                :button-color="$route.query.filter === 'today'
                                || $route.query.filter ===''
                                || $route.query.filter === null?'red':'green'"
                label="Aujourd'hui"
            />
            <link-button
                :to="`?filter=week&status=${$route.query.status}`"
                :button-color="$route.query.filter === 'week'?'red':'green'"
                label="Cette Semaine"
            />
            <link-button
                :to="`?filter=month&status=${$route.query.status}`"
                :button-color="$route.query.filter === 'month'?'red':'green'"
                label="Ce Mois ci"/>
            <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
                locale="fr"
            >
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="flex flex-col sm:flex-row justify-start items-center">
                  <div class="relative flex-grow">
                    <svg
                        class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                      <path
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                    />
                  </div>
                  <span class="flex-shrink-0 m-2">
              <svg
                  class="w-4 h-4 stroke-current text-gray-600"
                  viewBox="0 0 24 24"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
                  <div class="relative flex-grow">
                    <svg
                        class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                      <path
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                    />
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="grid gap-6 md:grid-cols-5 my-10">
          <LinkButton
              :to="`?status=all&filter=${$route.query.filter}`"
              :button-color="$route.query.status === 'all'
                                || $route.query.status ===''
                                || $route.query.status ==='null'
                                || $route.query.status === null?'red':'green'"
              label="Tous"
          />
          <link-button
              :to="`?status=completed&filter=${$route.query.filter}`"
              :button-color="$route.query.status === 'completed'?'red':'green'"
              label="Complet"
          />
          <link-button
              :to="`?status=processing&filter=${$route.query.filter}`"
              :button-color="$route.query.status === 'processing'?'red':'green'"
              label="En traitement"/>
          <link-button
              :to="`?status=pending&filter=${$route.query.filter}`"
              :button-color="$route.query.status === 'pending'?'red':'green'"
              label="En attente"/>
          <link-button
              :to="`?status=canceled&filter=${$route.query.filter}`"
              :button-color="$route.query.status === 'canceled'?'red':'green'"
              label="Annulé"/>
        </div>
      </div>
    </div>
    <div class="container bg-white min-h-64 rounded-md shadow-sm p-6">

      <b-table
          :data="transactions"
          :striped="true"
          :hoverable="true"
          :loading="isLoading"
          :focusable="true"
          :mobile-cards="true"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :pagination-rounded="isPaginationRounded"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          default-sort="_id"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
      >

        <b-table-column field="reference" label="Référence" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props">
          {{ props.row.reference }}
        </b-table-column>

        <b-table-column field="total" label="Commande" :td-attrs="columnTdAttrs" v-slot="props">
          {{ props.row.total | currency }}
        </b-table-column>

        <b-table-column field="customer.fullName" sortable label="Client" :td-attrs="columnTdAttrs" v-slot="props">
          <div>{{ props.row.user.firstName }} {{ props.row.user.lastName }}</div>
          <div>{{ props.row.user.email }}</div>
        </b-table-column>

        <b-table-column field="status" label="Status" :th-attrs="dateThAttrs" :td-attrs="columnTdAttrs" centered
                        v-slot="props">
                <span class="tag is-success">
                    {{ props.row.status }}
                </span>
        </b-table-column>

        <b-table-column field="createdAt" label="Date" :th-attrs="dateThAttrs" :td-attrs="columnTdAttrs" centered
                        v-slot="props">
          {{ new Date(props.row.createdAt).toLocaleDateString() }}
        </b-table-column>

        <b-table-column label="Actions" :td-attrs="columnTdAttrs" v-slot="props">
          <div v-if="props.row.order._id">
            <b-button tag="router-link" icon-left="eye" type="is-primary"
                      :to="`/dashboard/order/view/${props.row.order._id}`"/>
          </div>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">Aucune transaction enregistré</div>
        </template>

      </b-table>
    </div>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";
import LinkButton from "@/components/LinkButton";

export default {
  name: "index",
  components: {LinkButton, ListView},
  created() {
    this.getTransactions()
  },
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 15,
      isLoading: false,
      range: {
        start: new Date(),
        end: new Date()
      },
      transactions: []
    }
  },
  methods: {
    async getTransactions() {
      this.isLoading = true;
      try {
        const {data, status} = await this.$http.get('transaction')

        if (status === 200) {
          this.transactions = data;
        }

      } catch (e) {
        console.log(e)
      }
      this.isLoading = false;

    },
    dateThAttrs(column) {
      return column.label === 'Date' ? {
        title: 'This title is sponsored by "th-attrs" prop',
        class: 'has-text-success'
      } : null
    },
    columnTdAttrs(row, column) {
      if (row.id === 'Total') {
        if (column.label === 'ID') {
          return {
            colspan: 4,
            class: 'has-text-weight-bold',
            style: {
              'text-align': 'left !important'
            }
          }
        } else if (column.label === 'Gender') {
          return {
            class: 'has-text-weight-semibold'
          }
        } else {
          return {
            style: {display: 'none'}
          }
        }
      }
      return null
    }
  }
};
</script>

<style scoped>

</style>
