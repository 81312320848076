import { mapGetters } from "vuex";
// import { ADMIN, CONTRIB, MANAGER, SUPERVISOR } from "@/utils/constants";

export default {
  name: "AuthHandler",
  mounted() {
    // this.checkAuth();
  },
  methods: {
    ...mapGetters(["authStatus", "accessToken"]),
    async setLogin(data) {
      const payload = data;
      await this.$store.dispatch("SetAuth", data);
      localStorage.setItem("authentication", JSON.stringify(payload));
    },
    setLogout() {
      this.$store.dispatch("SetLogout");
      localStorage.removeItem("authentication");
    },
    async checkAuth() {
      // const { data, status } = await this.$http.get("/auth");
      // if (status >= 200 && status <= 230) {
      //   if (data.isAuthenticated) {
      //     // if (
      //     //   data.user?.roles?.includes(ADMIN) ||
      //     //   data.user?.roles?.includes(CONTRIB) ||
      //     //   data.user?.roles?.includes(MANAGER) ||
      //     //   data.user?.roles?.includes(SUPERVISOR)
      //     // ) {
      //       if (!this.authStatus)
      //         await this.setLogin({ token: this.accessToken, ...data });
      //     // }
      //   } else {
      //     this.setLogout();
      //   }
      // }
    },
  },
};
