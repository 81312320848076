<template>
  <div class="container  mx-auto p-4">
    <header class="mb-10">
      <header v-if="title !== ''" class="mb-20">
        <h1 class="text-2xl font-bold text-gray-500 uppercase">
          {{ title }}
        </h1>
      </header>
      <section class="mt-6">
        <slot name="toolbar"/>
      </section>
    </header>
    <section class="mt-8">
      <slot/>
    </section>
  </div>
</template>

<script>
export default {
  name: "ListView",
  props: {
    title: {
      type: String,
      default: () => ''
    }
  }
};
</script>

<style scoped>

</style>
