<template>
  <main class="h-full w-full">
    <div class="container mx-auto">
      <div class=" flex justify-center align-middle">
        <div class="grid grid-cols-1 xl:w-1/3 md:w-1/2">
          <div class="my-16">
            <figure>
              <img src="~@/assets/images/logo.png" alt="">
            </figure>
          </div>
          <div class="grid-cols-1 grid  shadow-lg p-6">
            <div class="mb-4" v-if="errors">
              <b-message :title="errors.title" type="is-danger" has-icon aria-close-label="Close message"
                         size="is-small">
                <template v-if="Array.isArray(errors.message)">
                  <div class="block" v-for="(error, index) in errors.message" :key="index">{{ error }}</div>
                </template>
                <div class="block" v-else>{{ errors.message }}</div>
              </b-message>
            </div>
            <div>
              <TextInput label-text="Email" v-model="credentials.email"/>
            </div>
            <div>
              <TextInput input-type="password" label-text="Mot de passe" v-model="credentials.password"/>
            </div>
            <div class="flex flex-row justify-center">
              <b-button type="is-primary" :loading="isLoading" @click="submitForm" label="Se Connecter" icon-right="login"/>
            </div>
          </div>

        </div>
      </div>

    </div>
  </main>
</template>

<script>
import TextInput from "@/components/TextInput";
import AuthHandler from "@/mixins/AuthHandler";

export default {
  name: "LoginPage",
  components: {TextInput},
  mixins: [AuthHandler],
  data() {
    return {
      isLoading: false,
      errors: null,
      credentials: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      this.errors = null;
      try {
        const {data, status} = await this.$http.post("/auth/", this.credentials);

        if (status === 201) {

          if (data.user.roles.includes('ADMIN')) {
          await this.setLogin({...data, isAuthenticated: true});
          await this.$router.push("/");
          }
        }
      } catch (e) {
        this.errors = {
          tilte: e.response.data.error,
          message: e.response.data.message
        };
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>

</style>
