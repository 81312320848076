<template>
  <div class="container mx-auto px-6 mt-20">
    <header v-if="title !== ''">
      <h2 class="uppercase text-gray-600 text-2xl font-bold my-10">{{ title }}</h2>
    </header>
    <form class="overflow-hidden rounded-lg shadow-md bg-white p-6" @submit.prevent="$emit('submit')">
      <div class="mb-4" v-if="errors">
        <b-message :title="errors.title" type="is-danger" has-icon aria-close-label="Close message"
                   size="is-small">
          <template v-if="Array.isArray(errors.message)">
            <div class="block" v-for="(error, index) in errors.message" :key="index">{{ error }}</div>
          </template>
          <div class="block" v-else>{{ errors.message }}</div>
        </b-message>
      </div>
      <slot/>
      <div class="content-center flex space-x-2 align-middle mt-10">
        <b-button :loading="isProcessing"
                  :label="isUpdate? 'Mettre à jour' : buttonLabel"
                  native-type="submit" class="is-primary"/>
        <sync-loader v-show="isProcessing" color="#6aa352"/>
      </div>
    </form>
  </div>
</template>

<script>
import SyncLoader from "vue-spinner/src/SyncLoader";

export default {
  name: "EditForm",
  components: {
    SyncLoader,
  },
  props: {
    title: {
      type: String,
      default: () => ""
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean
    },
    errors: {
      type: [Object, Boolean, Array]
    },
    buttonLabel: {
      type: String,
      default: () => "Créer"
    }
  }
};
</script>

<style scoped>

</style>
