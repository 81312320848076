<template>
  <ListView title="Gestion des menus">
    <template #toolbar>
      <div class="grid md:grid-cols-5 gap-2">
        <div>
          <b-button tag="router-link" type="is-primary" label="Nouveau Menu" to="/dashboard/menu/create"/>
        </div>
      </div>
    </template>
    <div class="container bg-white min-h-64 rounded-md shadow-sm p-6">
      <b-table
          :data="menus"
          :loading="isLoading"
      >
        <b-table-column field="key" label="Clé" v-slot="props">
          {{ props.row.key }}
        </b-table-column>
        <b-table-column field="name" label="Nom" v-slot="props">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Actions" v-slot="props">
          <div class="buttons">
            <b-button
                tag="router-link"
                type="is-primary"
                :to="`/dashboard/menu/edit/${props.row._id}`"
                icon-right="lead-pencil"/>
            <b-button @click="removeMenu(props.row._id)" icon-left="delete"/>

          </div>
        </b-table-column>
      </b-table>

    </div>
  </ListView>

</template>

<script>
import ListView from "@/components/ListView";

export default {
  name: "index",
  components: {ListView},
  created() {
    this.getMenus()
  },
  data() {
    return {
      isLoading: false,
      menus: []
    };
  },
  methods: {
    async getMenus() {
      try {
        const {data, status} = await this.$http.get("menu");

        if (status === 200) {
          this.menus = data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async removeMenu(menuId) {
      const {status} = await this.$http.delete(`/menu/${menuId}`)

      if (status === 200) {
        const index = this.menus.findIndex(menu => menu._id === menuId);
        this.menus.splice(index, 1)
      }
    }
  }
};
</script>

<style scoped>

</style>
