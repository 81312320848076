<script>

    export default {
        name: "FileHandler",
        methods: {
            buildAttachmentLink(filename, type) {
                let folder;
                switch (type) {
                    case 'thumbnail':
                        folder = 'thumbnails';
                        break;
                    case 'article_attachment':
                        folder = 'articles/attachments';
                        break;
                    case 'article_image':
                        folder = 'articles/images';
                        break;
                    case 'article_personality':
                        folder = 'articles/personalities';
                        break;
                    case 'picture':
                        folder = 'pictures';
                        break;
                    case 'banner':
                        folder = 'banners';
                        break;
                    default:
                        folder = 'uploads';
                        break;
                }
                return `/storage/${folder}/${filename}`;
            },
            typeName(type) {
                let name;
                switch (type) {
                    case 'thumbnail':
                        name = 'Apercu';
                        break;
                    case 'article_attachment':
                        name = 'Article';
                        break;
                    case 'article_image':
                        name = 'Article';
                        break;
                    case 'article_personality':
                        name = 'Article';
                        break;
                    case 'picture':
                        name = 'Photo';
                        break;
                    default:
                        name = 'Fichier';
                        break;
                }
                return name;
            },
            formatBytes(bytes, decimals = 2) {
                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
        }
    }
</script>
