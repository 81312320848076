<template>
  <EditForm @submit="submitAttribute" :is-update="isUpdate" class="pb-6">
    <div class="container">
      <div class="grid grid-cols-1 gap-2">
        <div>
          <b-field label="Nom">
            <b-input v-model="attribute.name"/>
          </b-field>
        </div>
        <div>
          <b-field label="Type">
            <b-select placeholder="Choisir le type" expanded
                      v-model="attribute.type">
              <option
                  v-for="attributeType in attributeTypes"
                  :value="attributeType.value"
                  :key="attributeType.value">
                {{ attributeType.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="grid grid-cols-1 " label="Termes"
             v-if="attribute.type === 'SELECTABLE' || attribute.type === 'COLOR'">
          <b-field v-for="(value, index) of attribute.terms" :key="index">
            <b-input v-if="attribute.type === 'SELECTABLE'" size="is-small"
                     v-model="attribute.terms[index].value"/>
            <v-swatches v-else v-model="attribute.colors[index]"/>
            <b-button size="is-small" @click="removeTerm(index)" icon-left="delete"/>
          </b-field>
        </div>
        <div v-if="attribute.type === 'SELECTABLE' || attribute.type === 'COLOR'">
          <b-button icon-left="plus" @click="addTerm" size="is-small"/>
        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: "AttributeEdit",
  components: {EditForm, VSwatches},
  data() {
    return {
      isUpdate: false,
      attributeTypes: [
        // {name: "Text", value: "TEXT"},
        // {name: "Chiffre", value: "NUMBER"},
        {name: "Selection", value: "SELECTABLE"},
        // {name: "Couleur", value: "COLOR"}
      ],
      attribute: {
        name: "",
        type: "SELECTABLE",
        terms: [{value: ""}],
        colors: ['#1CA085'],
      }
    };
  },
  methods: {
    reset() {
      this.attribute = {
        title: "",
        type: "TEXT",
        value: ""
      };
    },
    editForm(updateAttribute) {
      this.attribute = {
        ...updateAttribute
      };
      this.isUpdate = true;
    },
    addTerm() {
      this.attribute.terms.push({
        value: "",
        color: "#1CA085"
      });
    },
    removeTerm(index) {
      this.attribute.terms.splice(index, 1);
    },
    async submitAttribute() {
      if (!this.isUpdate) {
        const {status, data} = await this.$http.post("attribute", this.attribute);

        if (status === 201) {
          this.$emit("created", data);
          this.reset();
        }
      } else {
        const {status} = await this.$http.put("attribute/" + this.attribute._id, this.attribute);

        if (status === 200) {
          this.$emit("updated", this.attribute);
          this.reset();
        }
      }

    }
  }
}
;
</script>

<style scoped>

</style>
