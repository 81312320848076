<template>
  <img src="@/assets/images/add.svg"  style="width: 100%; height: 100%;" alt="">
</template>

<script>
export default {
    name: "AddIcon"
}
</script>

<style scoped>

</style>
