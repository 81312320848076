<template>
  <EditForm title="" @submit="submitAttribute" button-label="Ajouter">
    <div class="container">
      <div class="grid grid-cols-1 gap-2">
        <div>
          <TextInput label-text="Nom" v-model="category.name" />
        </div>
      </div>
    </div>
  </EditForm>
</template>

<script>
import EditForm from "@/components/EditForm";
import TextInput from "@/components/TextInput";

export default {
  name: "CategoryEdit",
  props: {
    categories: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    TextInput, EditForm
  },
  data() {
    return {
      category: {
        name: ""
      }
    };
  },
  methods: {
    reset() {
      this.category = {
        name: ""
      };
    },
    async submitAttribute() {

      const { data } = await this.$http.post("article_category", this.category);

      if (data) {
        this.$emit("created", this.category);
        this.reset();
      }
    },
    insertThumbnail() {
      const self = this;
      this.showFileManager(payload => {
        self.thumbnails = payload.link;
        self.product.images = payload.selected;
      }, true);
    }
  }
};
</script>

<style scoped>

</style>
