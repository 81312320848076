<template>
  <ListView title="">
    <b-table
        :data="attributes"
        :striped="true"
        :loading="false"
        paginated
        :per-page="10"
        >
      <b-table-column field="name" label="Nom" width="100"  v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="type" label="Type" width="40"  v-slot="props">
        {{ props.row.type }}
      </b-table-column>
      <b-table-column label="Actions" width="40"  v-slot="props">
        <div class="grid grid-cols-2 gap-1">
          <b-button icon-left="lead-pencil" size="is-small"
                    @click="$emit('edit', props.row)"/>

          <b-button icon-left="delete" size="is-small"
                    @click="$emit('remove', props.row._id)"/>
        </div>
      </b-table-column>
    </b-table>
  </ListView>
</template>

<script>
import ListView from "@/components/ListView";

export default {
  name: "TagList",
  components: {ListView},
  props: {
    attributes: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>

<style scoped>

</style>
