<template>
  <Box>
    <div class="container mx-auto">
      <div class="grid grid-cols-1 gap-8">
        <div class="">
          <b-field label="Type de pricing">
            <b-select

                placeholder="Sélectionner un type"
                v-model="pricing.type"
            >
              <option
                  v-for="option in pricingBy"
                  :value="option.id"
                  :key="option.id">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <b-field grouped v-for="(interval, index) of pricing.intervals"
                   :key="index">
            <b-field label="De">
              <b-input type="number" v-model="pricing.intervals[index].min"/>
              <div
                  class="border font-semibold w-auto px-2 bg-secondary text-primary justify-center flex items-center">
                <span>{{ intervalLabel }}</span>
              </div>
            </b-field>
            <b-field label="à">
              <b-input type="number" v-model="pricing.intervals[index].max"/>
              <div
                  class="border font-semibold w-auto px-2  bg-secondary text-primary justify-center flex items-center">
                <span>{{ intervalLabel }}</span>
              </div>
            </b-field>
            <b-field class="col-span-2" label="Prix">
              <b-input type="number" v-model="pricing.intervals[index].price"/>
              <div
                  class="border font-semibold w-auto px-2  bg-secondary text-primary justify-center flex items-center">
                <span>CFA</span>
              </div>
            </b-field>
            <div class="flex justify-center content-center items-end" v-if="index !== 0">
              <b-button icon-left="delete" @click="removePricing(index)"/>
            </div>
          </b-field>
        </div>
        <div class="my-4">
          <b-button size="is-small"
                    icon-left="plus"
                    @click="addPricing"
                    type="is-primary"
          >
            Ajouter un interval
          </b-button>
        </div>
        <div>
          <b-button type="is-primary" @click="submitPricing" label="Enregistrer" icon-left="save"/>
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/Box";

export default {
  name: "DeliverySetting",
  components: {Box},
  props: {
    settings: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.fetchPricing()

  },
  data() {
    return {
      pricingBy: [
        {name: 'Montant', id: 'AMOUNT'},
        {name: 'Poid', id: 'WEIGHT'},
      ],
      calculationType: [
        {name: "% | Variable", value: "VARIABLE"},
        {name: "CFA | Fixe", value: "FIXED"},
      ],
      pricing: {
        type: 'AMOUNT',
        intervals: [{min: 0, max: 0, price: 0}]
      }
    }
  },
  watch: {
    settings() {
      this.fetchPricing()
    }
  },
  computed: {
    metadata() {
      return function (key) {
        const settings = this.settings.filter(setting => setting.key === key)

        if (settings.length) {
          return settings[0].metadata;
        }

        return null
      }
    },
    intervalLabel() {
      if (this.pricing.type === 'WEIGHT') {
        return "KG"
      }
      return "CFA"
    }
  },
  methods: {
    fetchPricing() {
      if (this.metadata('DELIVERY_PRICING')) {
        this.pricing = this.metadata('DELIVERY_PRICING');
      }
    },
    addPricing() {
      this.pricing.intervals.push({min: 0, max: 0, price: 0})
    },
    removePricing(index) {
      this.pricing.intervals.splice(index, 1);
    },
    async submitPricing() {
      this.$emit('change', 'DELIVERY_PRICING', this.pricing)
    }
  }
}
</script>

<style scoped>

</style>
