<template>
  <div id="home">

    <!-- breadcrumb -->
    <nav class="text-sm font-semibold mb-6" aria-label="Breadcrumb">
      <ol class="list-none p-0 inline-flex">
        <li class="flex items-center text-red-500">
          <a href="#" class="text-gray-700">Accueil</a>
          <svg class="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
        </li>
        <li class="flex items-center">
          <a href="#" class="text-gray-600">Dashboard</a>
        </li>
      </ol>
    </nav>
    <!-- breadcrumb end -->

    <div class="lg:flex justify-between items-center mb-6">
      <p class="text-2xl font-semibold mb-2 lg:mb-0">Bonjour!</p>
<!--      <button class="bg-red-500 hover:bg-red-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">View Logs</button>-->
    </div>

    <div class="flex flex-wrap -mx-3 mb-20">
      <div class="w-1/2 xl:w-1/5 px-3">
        <div class="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
          <div class="text-primary">
            <p class="font-semibold text-3xl">{{ stats.completed }}</p>
            <p>Commandes livré</p>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/5 px-3">
        <div class="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
          <div class="text-primary">
            <p class="font-semibold text-3xl">{{ stats.pending }}</p>
            <p>En attentes</p>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/5 px-3">
        <div class="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
          <div class="text-primary">
            <p class="font-semibold text-3xl">{{ stats.handling }}</p>
            <p>En livraisons</p>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/5 px-3">
        <div class="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
          <div class="text-primary">
            <p class="font-semibold text-3xl">{{ stats.canceled }}</p>
            <p>Commandes annulés</p>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/5 px-3">
        <div class="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
          <div class="text-primary">
            <p class="font-semibold text-3xl">{{ stats.profit }}</p>
            <p>Gains journaliés</p>
          </div>
        </div>
      </div>

    </div>


    <div class="flex flex-wrap -mx-3">

      <div class="w-full xl:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">Dernières Ventes</p>

        <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas id="buyers-chart" width="600" height="400"></canvas>
        </div>
      </div>

      <div class="w-full xl:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">Top Produits</p>

        <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas id="reviews-chart" width="600" height="400"></canvas>
        </div>
      </div>

      <div class="w-full xl:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">Dernières commandes</p>
        <div class="w-full bg-white border rounded-lg p-4">
          <div class="w-full bg-white border rounded-lg flex justify-between items-center px-4 py-2 mb-4"
               v-for="order of lastOrders" :key="order._id"
          >
            <div>
              <p class="font-semibold text-xl">{{ order.deliveryAddress.address }}</p>
              <p>{{ order.user.phone }}</p>
            </div>
            <span class="text-green-500 font-semibold text-lg">{{ order.transaction.total | currency }}</span>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'DashboardHome',
  created() {
    this.getStats()
    this.getOrders()
  },
  data() {
    return {
      buyersData: {
        type: 'line',
        data: {
          labels : ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"],
          datasets:[{
            backgroundColor : "rgba(99,179,237,0.4)",
            strokeColor : "#63b3ed",
            pointColor : "#fff",
            pointStrokeColor : "#63b3ed",
            data : [203,156,99,251,305,247,256]
          },
            {
              backgroundColor : "rgba(198,198,198,0.4)",
              strokeColor : "#f7fafc",
              pointColor : "#fff",
              pointStrokeColor : "#f7fafc",
              data : [86,97,144,114,94,108,156]
            }]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                display:false
              },
              ticks: {
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      reviewsData: {
        type: 'bar',
        data: {
          labels : ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"],
          datasets:[{
            backgroundColor : "rgba(99,179,237,0.4)",
            strokeColor : "#63b3ed",
            pointColor : "#fff",
            pointStrokeColor : "#63b3ed",
            data : [203,156,99,251,305,247,256]
          }]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                display:false
              },
              ticks: {
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        }

      },
      lastOrders: [],
      stats: {
        handling: 0,
        completed: 0,
        pending: 0,
        canceled: 0,
        profit: 0,
      },
    }
  },
  mounted () {
    new Chart(document.getElementById('buyers-chart'), this.buyersData)
    new Chart(document.getElementById('reviews-chart'), this.reviewsData)
  },
  methods:{
    async getStats() {
      const {data, status} = await this.$http.get(`order/stats`, {
        params: {
          company: this.$route.params.id
        }
      });

      if (status === 200) {
        this.stats = {...this.stats, ...data};
      }
    },
    async getOrders() {
      try {
        const {data, status} = await this.$http.get('order', {
          params: {
            limit: 5
          }
        })

        if (status === 200) {
          this.lastOrders = data
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
