<template>
  <ListView title="Liste des Produits">
    <template #toolbar>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-6">
        <div>
          <b-button expanded type="is-primary" tag="router-link" label="Nouveau Produit"
                    to="/dashboard/product/create"/>
        </div>
        <div>
          <b-button expanded type="is-primary" tag="router-link" label="Gérer les Catégories"
                    to="/dashboard/product/category"/>
        </div>
        <div>
          <b-button expanded type="is-primary" tag="router-link" label="Gérer les Étiquettes"
                    to="/dashboard/product/tag"/>
        </div>
        <div>
          <b-button expanded type="is-primary" tag="router-link" label="Gérer les Marques"
                    to="/dashboard/product/brand"/>
        </div>
        <div>
          <b-button expanded type="is-primary" tag="router-link" label="Gérer les Attributs"
                    to="/dashboard/product/attribute"/>
        </div>
      </div>
    </template>
    <div class="container bg-white min-h-64 rounded-md shadow-sm p-6">
      <b-field grouped group-multiline>
        <b-field>
          <b-select v-model="perPage">
            <option value="10">10 par page</option>
            <option value="20">20 par page</option>
            <option value="40">40 par page</option>
            <option value="60">60 par page</option>
            <option value="80">80 par page</option>
            <option value="100">100 par page</option>
          </b-select>
        </b-field>
        <b-field>
          <b-select v-model="selectedCategory" @input="filterCategory">
            <option :value="null">Toutes les catégories</option>
            <option
                v-for="category of categories"
                :key="category._id"
                :value="category._id"
            >
              {{ category.name }}
            </option>
          </b-select>
        </b-field>
        <b-button icon-left="microsoft-excel" @click="downloadExcel">Exporter Excel</b-button>

      </b-field>

      <b-table
          :data="products"
          :hoverable="true"
          :loading="isLoading"
          :focusable="false"
          :mobile-cards="false"
          paginated
          :per-page="perPage"
          @page-change="onPageChange"
          :current-page="currentPage"
      >

        <b-table-column field="count" sortable label="#" width="40" :td-attrs="columnTdAttrs" numeric
                        v-slot="props">
          {{ props.row.count }}
        </b-table-column>
        <b-table-column field="image" label="" width="40" :td-attrs="columnTdAttrs" numeric
                        v-slot="props">
          <img :src="props.row.image_url" alt="" class="w-16 h-8">
        </b-table-column>
        <b-table-column :searchable="true" sortable field="name" label="Nom du produit" width="250"
                        :td-attrs="columnTdAttrs"
                        v-slot="props">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column :searchable="true" field="sku" label="SKU" width="180" :td-attrs="columnTdAttrs"
                        v-slot="props">
          {{ props.row.sku }}
        </b-table-column>

        <b-table-column field="price" label="Prix" :td-attrs="columnTdAttrs" v-slot="props">
          <div>

            <v-popover
                offset="16"
                :id="props.row._id"
            >
              <b-button type="is-text">{{ props.row.price | currency }}</b-button>
              <template slot="popover">
                <div class="">
                  <b-field>
                    <div class="is-hidden">{{ newPrice[props.row._id] = props.row.price }}</div>
                    <b-input type="number" v-model="newPrice[props.row._id]"/>
                    <b-button icon-left="content-save" :loading="priceSaving[props.row._id]"
                              @click="changePrice(props.row._id)" v-close-popover/>
                  </b-field>
                </div>

              </template>
            </v-popover>

          </div>
        </b-table-column>

        <b-table-column sortable field="status" label="Statut" :td-attrs="columnTdAttrs" centered
                        v-slot="props">
          <div class="cursor-pointer">
            <v-popover
                offset="16"
                :id="props.row._id"
            >
              <ProductStatusBadge :status="props.row.status"/>
              <template slot="popover">
                <div class="">
                  <div class="buttons grid-cols-2 grid">
                    <b-button size="is-small" label="Actif" :loading="isStatusLoading"
                              @click="changeStatus(props.row._id, 'ACTIVE')" v-close-popover/>
                    <b-button size="is-small" label="Rupture" :loading="isStatusLoading"
                              @click="changeStatus(props.row._id, 'SOLD_OUT')" v-close-popover/>
                    <b-button size="is-small" label="Désactivé" :loading="isStatusLoading"
                              @click="changeStatus(props.row._id, 'DISABLED')" v-close-popover/>
                  </div>
                </div>

              </template>
            </v-popover>
            <!--                    {{ props.row.status }}-->
          </div>
        </b-table-column>

        <b-table-column field="createdAt" label="Créé le" :td-attrs="columnTdAttrs" centered
                        v-slot="props">
          {{ (new Date(props.row.createdAt)).toLocaleDateString() }}
        </b-table-column>

        <b-table-column field="hasStock" label="Actions" :td-attrs="columnTdAttrs" v-slot="props">
          <div class="grid grid-cols-1 lg:grid-cols-3 gap-2">
            <b-button type="is-primary" icon-left="eye" tag="router-link"
                      :to="`/dashboard/product/view/${props.row.slug}`"/>
            <b-button type="is-primary" icon-left="delete" @click="remove(props.row.id)"/>
            <b-button tag="a" type="is-primary" icon-left="link" target="_blank"
                      :href="`https://electroniccorp.sn/produits/${props.row.slug}`"/>
          </div>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">aucun produit</div>
        </template>

      </b-table>

    </div>
  </ListView>

</template>

<script>
import ListView from "@/components/ListView";
import FileHandler from "@/components/file-manager/FileHandler";
import {exportToSpreadsheet} from "@/utils/excel";
import ProductExtend from "@/mixins/ProductExtend";
import ProductStatusBadge from "@/components/ProductStatusBadge";


export default {
  name: "index",
  components: {ProductStatusBadge, ListView},
  mixins: [FileHandler, ProductExtend],
  created() {
    this.currentPage = this.$route.query.page || 1;
    this.getProducts();
    this.getCategories();
  },
  data() {
    return {
      categories: [],
      selectedCategory: null,
      currentPage: 1,
      products: [],
      isLoading: false,
      perPage: 40,
      priceSaving: [],
      newPrice: [],
      isStatusLoading: false,
      initialProducts: []
    };
  },
  methods: {
    filterCategory($event) {
      if ($event) {
        this.products = this.initialProducts.filter(product => product.categories.filter(category => category._id === $event).length)
        console.log(this.products)


      } else {
        this.products = this.initialProducts
      }
    },
    downloadExcel() {
      const productArray = [["Nom du produit", "SKU", "Statut", "Prix", "Catégories"], ...this.products.map(product => {

        return [product.name, product.sku, product.status, product.price, product.categories.map(category => category.name).join(' ,')]
      })];

      exportToSpreadsheet(productArray, 'excel')
    },
    onPageChange($event) {
      this.$router.push({path: '/dashboard/product/list', query: {page: $event}})
    },
    columnTdAttrs(row, column) {
      if (row.id === 'Total') {
        if (column.label === 'ID') {
          return {
            colspan: 4,
            class: 'has-text-weight-bold',
            style: {
              'text-align': 'left !important'
            }
          }
        } else if (column.label === 'Gender') {
          return {
            class: 'has-text-weight-semibold'
          }
        } else {
          return {
            // style: {display: 'none'}
          }
        }
      }
      return null
    },
    async changePrice(productId) {
      this.priceSaving[productId] = true;
      try {
        const {status, data} = await this.$http.patch(`product/${productId}/price`, {
          price: this.newPrice[productId],
        });

        if (status === 200) {
          const index = this.products.findIndex(product => product._id === productId);
          this.products.splice(index, 1, data);
        }
      } catch (e) {
        console.log(e);
      }
      this.priceSaving[productId] = false;
    },
    async changeStatus(productId, newStatus) {
      this.isStatusLoading = true;
      try {
        const {status, data} = await this.$http.patch(`product/${productId}/status`, {
          status: newStatus,
        });

        if (status === 200) {
          const index = this.products.findIndex(product => product._id === productId);
          this.products.splice(index, 1, data);
        }
      } catch (e) {
        console.log(e);
      }
      this.isStatusLoading = false;
    },
    async getProducts() {
      this.isLoading = true;
      try {
        const {data} = await this.$http.get("product/for_admin");

        if (data) {
          this.products = data.map((product, index) => {
            return {
              count: index + 1,
              ...product

            }
          });
          this.initialProducts = this.products
        }
      } catch (e) {
        console.log(e)
      }
      this.isLoading = false;
    },
    async remove(productId) {
      const {status} = await this.$http.delete("product/" + productId);

      if (status === 200) {
        const index = this.products.findIndex(product => product._id === productId)
        this.products.splice(index, 1);
      }
    },
    async getCategories() {
      const {data, status} = await this.$http.get("product_category/last_level");

      if (status === 200) {
        this.categories = data;
      }
    },
  }
};
</script>

<style scoped>

</style>
